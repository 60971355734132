// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
    display: block;
    margin-left: 0px;
    margin-right: auto;
    width: 100%;
    background: "rgba(0, 0, 0, 0.7)",
  }
  .find-heading {
    display: block;
    margin-left: 20px;
    margin-top: 100px;
    margin-right: auto;
    color: "#fff";
    font-size: 3em;
  }
.home__headers{
  display: flex;
  justify-content: center;
  font-size: 1.7em;
}
@media (max-width: 600px) {
  .find-heading {
    font-size: 2em;
  }
  .home__headers{
    font-size: 1.5em;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .find-heading {
    font-size: 2.5em;
  }
  .home__headers{
    font-size: 1.5em;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/realestate/Home.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX;EACF;EACA;IACE,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,cAAc;EAChB;AACF;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE;IACE,cAAc;EAChB;EACA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".center {\n    display: block;\n    margin-left: 0px;\n    margin-right: auto;\n    width: 100%;\n    background: \"rgba(0, 0, 0, 0.7)\",\n  }\n  .find-heading {\n    display: block;\n    margin-left: 20px;\n    margin-top: 100px;\n    margin-right: auto;\n    color: \"#fff\";\n    font-size: 3em;\n  }\n.home__headers{\n  display: flex;\n  justify-content: center;\n  font-size: 1.7em;\n}\n@media (max-width: 600px) {\n  .find-heading {\n    font-size: 2em;\n  }\n  .home__headers{\n    font-size: 1.5em;\n  }\n}\n\n@media (min-width: 601px) and (max-width: 900px) {\n  .find-heading {\n    font-size: 2.5em;\n  }\n  .home__headers{\n    font-size: 1.5em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
