// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgcolor {
  background-color: #F27A7A;
}

.container {
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  flex: auto;
}

.map-container {
  flex: auto;
  height: 100%;
  position: relative;
  max-width: calc(100% - 730px);
}

.results-container {
  width: 730px;
  overflow-y: auto;
}

.leaflet-top.leaflet-right {
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 600px) {
  .container {
    height: auto;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1100px) {
  .content-wrapper {
    flex-direction: column;
  }

  .map-container,
  .results-container {
    width: 100%;
    max-width: none;
  }
  .results-container {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/realestate/searchProperty/SearchProperty.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;;IAEE,WAAW;IACX,eAAe;EACjB;EACA;IACE,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;EACxB;AACF","sourcesContent":[".bgcolor {\n  background-color: #F27A7A;\n}\n\n.container {\n  background-color: #F7F7F7;\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.content-wrapper {\n  display: flex;\n  flex-direction: row;\n  flex: auto;\n}\n\n.map-container {\n  flex: auto;\n  height: 100%;\n  position: relative;\n  max-width: calc(100% - 730px);\n}\n\n.results-container {\n  width: 730px;\n  overflow-y: auto;\n}\n\n.leaflet-top.leaflet-right {\n  top: 10px;\n  right: 10px;\n}\n\n@media screen and (max-width: 600px) {\n  .container {\n    height: auto;\n    overflow-y: auto;\n  }\n}\n\n@media screen and (max-width: 1100px) {\n  .content-wrapper {\n    flex-direction: column;\n  }\n\n  .map-container,\n  .results-container {\n    width: 100%;\n    max-width: none;\n  }\n  .results-container {\n    margin-top: 5px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
