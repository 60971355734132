// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: #F9F9F9;
    display: flex;
  }
  .navbar .menu-icon {
    color: rgb(60, 60, 60);
  }
  
  .navbar .title {
    margin-left: 10px;
    font-size: 26px;
    font-weight: 800;
    color: rgb(60, 60, 60);
  }  
  .navbar__link {
    text-decoration: none !important;
  }
  .navbar__text {
    text-decoration: none;
  }
  .header__links {
    display: flex;
    margin-left: 60px;
  }
  .header__Rightlinks {
    display: flex;
    margin-left: auto;
  }
 
.username__font {
  font-weight: bold;
  text-transform: capitalize;
  color: rgb(60, 60, 60);
}

.email__font {
  font-size: 12px;
  text-transform: lowercase;
  color: rgb(60, 60, 60);
}
.user__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.list-item:hover {
    background-color: #f0f0f0;
  }`, "",{"version":3,"sources":["webpack://./src/components/common/Navbar/UserMenuBarStyles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;EACf;EACA;IACE,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,sBAAsB;EACxB;EACA;IACE,gCAAgC;EAClC;EACA;IACE,qBAAqB;EACvB;EACA;IACE,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,iBAAiB;EACnB;;AAEF;EACE,iBAAiB;EACjB,0BAA0B;EAC1B,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;IACI,yBAAyB;EAC3B","sourcesContent":[".navbar {\n    background-color: #F9F9F9;\n    display: flex;\n  }\n  .navbar .menu-icon {\n    color: rgb(60, 60, 60);\n  }\n  \n  .navbar .title {\n    margin-left: 10px;\n    font-size: 26px;\n    font-weight: 800;\n    color: rgb(60, 60, 60);\n  }  \n  .navbar__link {\n    text-decoration: none !important;\n  }\n  .navbar__text {\n    text-decoration: none;\n  }\n  .header__links {\n    display: flex;\n    margin-left: 60px;\n  }\n  .header__Rightlinks {\n    display: flex;\n    margin-left: auto;\n  }\n \n.username__font {\n  font-weight: bold;\n  text-transform: capitalize;\n  color: rgb(60, 60, 60);\n}\n\n.email__font {\n  font-size: 12px;\n  text-transform: lowercase;\n  color: rgb(60, 60, 60);\n}\n.user__info {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n.list-item:hover {\n    background-color: #f0f0f0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
