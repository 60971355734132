// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  }
.formControl {
    width: 80%;
  }
.error__message {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  color: "#D30A0A";
}`, "",{"version":3,"sources":["webpack://./src/components/user/Form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;AACF;IACI,UAAU;EACZ;AACF;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".login__form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 400px;\n    margin: 0 auto;\n  }\n.formControl {\n    width: 80%;\n  }\n.error__message {\n  font-size: 20px;\n  text-align: center;\n  margin-top: 10px;\n  color: \"#D30A0A\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
