import React from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import './PropertyCard.css';

const PropertyCard = ({ property }) => {
    const navigate = useNavigate();
    const propertyInfo = () => {
        navigate(`/homeinfor/${property._id}`, {
            state: {
                property: property,
            },
        });
    };

    function removeTrailingZeros(value) {
        let stringValue = value.toString();
    
        // Remove trailing zeros after the decimal point
        if (stringValue.includes('.')) {
          stringValue = stringValue.replace(/\.?0*$/, '');
        }
    
        return stringValue;
      }
    
      let totalPrice = property.price;
      let formattedPrice;
    
      if (totalPrice >= 1000000) {
    
        formattedPrice = removeTrailingZeros((totalPrice / 1000000).toFixed(2)) + 'M';
      } else if (totalPrice >= 100000) {
    
        formattedPrice = removeTrailingZeros((totalPrice / 100000).toFixed(2)) + ' Lakhs';
      } else {
    
        formattedPrice = removeTrailingZeros(totalPrice.toFixed(2));
      }

    return (
        <div className="property-card">
            <img src={property.images[0]} alt="house" className="property-image" />
            <div className="property-details">
                <div onClick={propertyInfo}>
                    <Typography variant="body2" component="div">{property.hideAddress ? property.address.split(' ').slice(1).join(' ') : property.address}</Typography>
                    <Typography variant="body2" color="text.secondary">{property.bedrooms} Bed, {property.bathrooms} Bath</Typography>
                    <Typography variant="body2" color="text.secondary">Price: Rs. {formattedPrice}</Typography>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
