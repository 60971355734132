import React, { useState } from "react";
import Axios from "axios";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperText, FormControl } from "@mui/material";
import { MuiTelInput } from 'mui-tel-input';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const API = process.env.REACT_APP_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ChangeMobileNoModal({userId,updateMobileNumber,setUserInfo}) {
  const defaultCountryCode = '+94';
  const [mobileNumber, setMobileNumber] = useState(defaultCountryCode);
  const [currentpassword, setCurrentpassword] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setSubmitted(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentpassword("");
    setMobileNumber(defaultCountryCode);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleMobileNoChange = (newValue) => {
    setMobileNumber(newValue);
    setLoginError("");
  }
  const handleChangeMobile = () => {
    setSubmitted(true);
    setLoginError("");

    if (!isMatchingPassword(currentpassword)) {
        console.log("Invalid password");
        return;
      }

    if (!isValidPhoneNumber(mobileNumber)) {
        console.log("Invalid phone number");
        return;
      }

    Axios.put(`${API}/api/v1/user/updatemobileno`, {userId, currentpassword, mobileNumber})
      .then((response) => {
        if (response) {
          setLoginError("");
          setOpenAlert(true);
          setUserInfo(prevUserDetails => ({
            ...prevUserDetails,
            mobileNumber: response.data.user.mobileNumber
          }));
          updateMobileNumber(response.data.user.mobileNumber);
          handleCloseModal();
        } else {
          console.error("Invalid response:", response);
          setLoginError("Error occurred. Please try again.");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        console.error(error.response.data.msg);
        // console.log(error.response.data.msg)
        if(error.response.data.msg === "Mismatch the password"){
          setLoginError("Password is incorrect.");
          setOpenAlert(false);
        }else{
        setLoginError("Error occurred. Please try again.");
        setOpenAlert(false);
        }
      });
  };

  const isValidPhoneNumber = (mobileNumber) => {
    const phoneNumberWithoutCountryCode = mobileNumber.replace(defaultCountryCode, '');
    return phoneNumberWithoutCountryCode.trim().length > 0;
  };

  const isMatchingPassword = (currentpassword) => {
    return currentpassword !== null && currentpassword.trim().length > 0;
};

  return (
    <div>
      <Button onClick={handleOpenModal}>
        Edit
      </Button>
      <Dialog open={openModal} onClose={handleCloseModal} >
        <DialogTitle>Change Mobile Number</DialogTitle>
        <DialogContent>
          <p>Enter the current password</p>
          <FormControl style={{ marginBottom: "10px",width: "100%" }} error={submitted && !isMatchingPassword(currentpassword)}>
              <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Current Password"
                value={currentpassword}
                onChange={(event) => setCurrentpassword(event.target.value)}
              />
              <FormHelperText>
                {submitted && !isMatchingPassword(currentpassword) ? (
                  <span style={{ color: "#D30A0A" }}>
                    Invalid Current Password
                  </span>
                ) : (
                  ""
                )}
              </FormHelperText>
            </FormControl>
            <p>Enter the new mobile number</p>
            <FormControl style={{ marginBottom: '10px' }}>
                <MuiTelInput
                value={mobileNumber}
                onChange={handleMobileNoChange}
                helperText={submitted && !isValidPhoneNumber(mobileNumber) ? "Invalid Mobile Number" : ""}
                error={submitted && !isValidPhoneNumber(mobileNumber)}
                />
            </FormControl>
          <div>
            {loginError && (
                <FormHelperText style={{ color: "#D30A0A", fontSize: "14px", textAlign: "center" }}>{loginError}</FormHelperText>
                )}
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangeMobile} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfully update the MobileNo!
        </Alert>
      </Snackbar>
    </div>
  );
}