import React, { useState, useEffect } from "react";
import {
  FormControl,
  TextField,
  Button,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { Link } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Axios from "axios";
import ChangePasswordModal from "../profile/ChangePasswordModal";
import ChangeMobileNoModal from "../profile/ChangeMobileNoModal";
import OTPVerification from "../profile/OTPVerification";
import DeleteModal from "../profile/DeleteModal";
import "./AgentprofileSettings.css";
const { BlobServiceClient } = require("@azure/storage-blob");

const API = process.env.REACT_APP_API;
const ACCOUNT_NAME = process.env.REACT_APP_ACCOUNT_NAME;
const CONTAINER_NAME = process.env.REACT_APP_CONTAINER_NAME;
const SAS = process.env.REACT_APP_SAS_PROFILE;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AgentprofileSettings({ setUserInfo, setIsLoggedIn }) {
  const location = useLocation();
  const userDetails = location.state ? location.state.user : null;
  if (!userDetails) {
    console.log("null");
  }
  const [email, setEmail] = useState(userDetails.email);
  const [firstName, setFirstName] = useState(userDetails.firstName);
  const [lastName, setLastName] = useState(userDetails.lastName);
  const [mobileNumber, setMobileNumber] = useState(userDetails.mobileNumber);
  const [description, setDescription] = useState(userDetails.description);
  const [agentLocation, setAgentLocation] = useState(userDetails.agentLocation);
  const [profilePicture, setProfilePicture] = useState(
    userDetails.profilePicture
  );
  const [image, setImage] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [editingFirstName, setEditingFirstName] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false);
  const [editingMobileNo, setEditingMobileNo] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [editingAgentLocation, setEditingAgentLocation] = useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleMobileNumberUpdate = (updateMobileNumber) => {
    setMobileNumber(updateMobileNumber);
  };

  const formatMobileNumber = (mobileNumber) => {
    const sanitizedMobileNumber = mobileNumber.replace(/\s/g, "");
    const visibleDigits = sanitizedMobileNumber.slice(-4);
    const maskedDigits = "*".repeat(sanitizedMobileNumber.length - 4 - 1);
    const formattedNumber = maskedDigits + visibleDigits;
    return formattedNumber;
  };

  const updateFirstName = () => {
    setSubmitted(true);
    setLoginError("");

    if (!isValidFirstName(firstName)) {
      console.log("Enter correct Firstname");
      return;
    }

    Axios.put(`${API}/api/v1/user/updatefirstname/${userDetails._id}`, {
      firstName,
    })
      .then((response) => {
        if (response) {
          setLoginError("");
          setUserInfo((prevUserDetails) => ({
            ...prevUserDetails,
            firstName: response.data.user.firstName,
          }));
          setFirstName(response.data.user.firstName);
          console.log(userDetails);

          setOpenAlert(true);
        } else {
          console.error("Invalid response:", response);
          setLoginError(
            "An error occurred when updating the FirstName. Please try again."
          );
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error:", error.response.data);
        setLoginError(
          "An error occurred when updating the FirstName. Please try again."
        );
      });
    setEditingFirstName(false);
  };

  const updateLastName = () => {
    setSubmitted(true);
    setLoginError("");

    if (!isValidLastName(lastName)) {
      console.log("Enter correct Lasttname");
      return;
    }

    Axios.put(`${API}/api/v1/user/updatelastname/${userDetails._id}`, {
      lastName,
    })
      .then((response) => {
        if (response) {
          setLoginError("");
          setUserInfo((prevUserDetails) => ({
            ...prevUserDetails,
            lastName: response.data.user.lastName,
          }));
          setLastName(response.data.user.lastName);
          setOpenAlert(true);
        } else {
          console.error("Invalid response:", response);
          setLoginError(
            "An error occurred when updating the LastName. Please try again."
          );
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error:", error.response.data);
        setLoginError(
          "An error occurred when updating the LastName. Please try again."
        );
      });
    setEditingLastName(false);
  };

  const updateAbotMe = () => {
    setSubmitted(true);
    setLoginError("");

    Axios.put(`${API}/api/v1/user/updateAbotMe/${userDetails._id}`, {
      description,
    })
      .then((response) => {
        if (response) {
          // console.log(response)
          setLoginError("");
          setUserInfo((prevUserDetails) => ({
            ...prevUserDetails,
            description: response.data.user.description,
          }));
          setDescription(response.data.user.description);
          setOpenAlert(true);
        } else {
          console.error("Invalid response:", response);
          setLoginError(
            "An error occurred when updating the Description. Please try again."
          );
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error:", error.response.data);
        setLoginError(
          "An error occurred when updating the Description. Please try again."
        );
      });
    setEditingDescription(false);
  };

  const updateAgentLocation = () => {
    setSubmitted(true);
    setLoginError("");

    Axios.put(`${API}/api/v1/user/agentlocation/${userDetails._id}`, {
      agentLocation,
    })
      .then((response) => {
        if (response) {
          setLoginError("");
          setUserInfo((prevUserDetails) => ({
            ...prevUserDetails,
            agentLocation: response.data.user.agentLocation,
          }));
          setAgentLocation(response.data.user.agentLocation);
          // console.log(userDetails)

          setOpenAlert(true);
        } else {
          console.error("Invalid response:", response);
          setLoginError(
            "An error occurred when updating the agentLocation. Please try again."
          );
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error:", error.response.data);
        setLoginError(
          "An error occurred when updating the agentLocation. Please try again."
        );
      });
    setEditingAgentLocation(false);
  };

  const handleFileUpload = async () => {
    setSubmitted(true);
    setLoginError("");

    try {
      if (!image) {
        setLoginError("Please select an image to upload.");
        return;
      }

      const blobClientConnectionURL = `https://${ACCOUNT_NAME}.blob.core.windows.net?${SAS}`;
      const blobServiceClient = new BlobServiceClient(blobClientConnectionURL);

      const containerClient =
        blobServiceClient.getContainerClient(CONTAINER_NAME);
      const baseBlobUrl = `https://${ACCOUNT_NAME}.blob.core.windows.net/${CONTAINER_NAME}/`;

      const file = image;
      const blobName = file.name.substring(file.name.lastIndexOf("/") + 1);
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      await blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
      });

      const uploadedImageUrl = baseBlobUrl + blobName;
      // console.log(`Image uploaded. URL: ${uploadedImageUrl}`);

      const response = await Axios.post(
        `${API}/api/v1/user/uploadprofilePicture/${userDetails._id}`,
        { uploadedImageUrl }
      );

      if (response.status === 200 && response.data.success) {
        setLoginError("");
        setOpenAlert(true);
        setUserInfo((prevUserDetails) => ({
          ...prevUserDetails,
          profilePicture: response.data.user.profilePicture,
        }));
        setProfilePicture(response.data.user.profilePicture);
        console.log("Profile picture uploaded successfully");
      } else {
        setOpenAlert(false);
        console.error("Failed to upload profile picture");
      }
    } catch (error) {
      setOpenAlert(false);
      console.error(
        "An error occurred while uploading profile picture:",
        error
      );
    }
  };

  const isValidFirstName = (firstName) => {
    return typeof firstName === "string" && firstName.trim().length > 0;
  };

  const isValidLastName = (lastName) => {
    return typeof lastName === "string" && lastName.trim().length > 0;
  };

  const handleFileSelect = (event) => {
    const selectedPhoto = event.target.files[0];
    // console.log(selectedPhoto)
    if (selectedPhoto) {
      const blobUrl = URL.createObjectURL(selectedPhoto);
      setImage(selectedPhoto);
      setProfilePicture(blobUrl);
    }
  };

  return (
    <>
      <div className="infor__form">
        <h1>Agent Personal Information</h1>
        <div>
          {loginError && (
            <FormHelperText
              style={{
                color: "#D30A0A",
                fontSize: "14px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {loginError}
            </FormHelperText>
          )}
        </div>
        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic-firstName"
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
                setLoginError("");
              }}
              helperText={
                submitted && !isValidFirstName(firstName)
                  ? "Invalid FirstName"
                  : ""
              }
              error={submitted && !isValidFirstName(firstName)}
              style={{ width: "75%" }}
              disabled={!editingFirstName}
            />
            {editingFirstName ? (
              <Button onClick={updateFirstName}>Save</Button>
            ) : (
              <Button onClick={() => setEditingFirstName(true)}>Edit</Button>
            )}
          </div>
        </FormControl>

        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic-lastName"
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
                setLoginError("");
              }}
              helperText={
                submitted && !isValidLastName(lastName)
                  ? "Invalid LastName"
                  : ""
              }
              error={submitted && !isValidLastName(lastName)}
              style={{ width: "75%" }}
              disabled={!editingLastName}
            />
            {editingLastName ? (
              <Button onClick={updateLastName}>Save</Button>
            ) : (
              <Button onClick={() => setEditingLastName(true)}>Edit</Button>
            )}
          </div>
        </FormControl>

        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              label="About Me"
              multiline
              rows={4}
              value={description}
              onChange={(event) => {
                setDescription(event.target.value);
                setLoginError("");
              }}
              style={{ width: "75%" }}
              disabled={!editingDescription}
            />
            {editingDescription ? (
              <Button onClick={updateAbotMe}>Save</Button>
            ) : (
              <Button onClick={() => setEditingDescription(true)}>Edit</Button>
            )}
          </div>
        </FormControl>

        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic-lastName"
              label="Location"
              variant="outlined"
              value={agentLocation}
              onChange={(event) => {
                setAgentLocation(event.target.value);
                setLoginError("");
              }}
              style={{ width: "75%" }}
              disabled={!editingAgentLocation}
            />
            {editingAgentLocation ? (
              <Button onClick={updateAgentLocation}>Save</Button>
            ) : (
              <Button onClick={() => setEditingAgentLocation(true)}>
                Edit
              </Button>
            )}
          </div>
        </FormControl>

        <div className="formControl">
          <TextField
            id="outlined-basic-email"
            label="Email"
            variant="outlined"
            value={email}
            style={{ width: "75%" }}
            disabled
          />
        </div>

        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <label htmlFor="profile-picture-input">
              <h4 style={{ color: "#4F4F4F" }}>
                Customize your profile picture with a personal photo
              </h4>
              <Button
                style={{ cursor: "pointer" }}
                onClick={() =>
                  document.getElementById("profile-picture-input").click()
                }
              >
                Upload
              </Button>
              <Button style={{ cursor: "pointer" }} onClick={handleFileUpload}>
                Save
              </Button>
            </label>
            <input
              type="file"
              accept="image/*"
              id="profile-picture-input"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <div className="profile-photo-preview">
              {profilePicture ? (
                <img src={profilePicture} alt="Profile" />
              ) : (
                <Avatar
                  src="/broken-image.jpg"
                  className="profile-photo-preview"
                />
              )}
            </div>
          </div>
        </FormControl>

        {/* </div>
    <div div className="security__form"> */}
        <h1>Sign In & Security</h1>

        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <h4>MobileNumber : Make your account extra secure.</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              id="formatted-mobile-number"
              variant="outlined"
              label="Mobile Number"
              value={formatMobileNumber(mobileNumber)}
              disabled={!editingMobileNo}
              InputProps={{
                readOnly: true,
              }}
              style={{ width: "75%", marginTop: "8px" }}
            />
            <ChangeMobileNoModal
              userId={userDetails._id}
              updateMobileNumber={handleMobileNumberUpdate}
              setUserInfo={setUserInfo}
            />
            {/* <OTPVerification userId={userDetails._id} mobileNo={mobileNumber} /> */}
          </div>
        </FormControl>

        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Password: Set a unique password</h4>
            <ChangePasswordModal userId={userDetails._id} />
          </div>
        </FormControl>
        {/* </div>
    <div className="security__form"> */}
        <h1>Manage Account</h1>
        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Delete: Permenatly delete the account with all infromation</h4>
            <DeleteModal
              userId={userDetails._id}
              setIsLoggedIn={setIsLoggedIn}
            />
          </div>
        </FormControl>
        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Terms & Conditions: Manage your personal data.</h4>
            <Link to="/termsandconditions">
              <Button>Terms & Conditions</Button>
            </Link>
          </div>
        </FormControl>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successful !
        </Alert>
      </Snackbar>
    </>
  );
}
