import React, { useEffect, useState } from "react";
import Axios from 'axios';
import jwt from "jwt-decode";
import SearchCard from "../../common/MediaCard/SearchCard";
import Skeleton from '@mui/material/Skeleton';
import "../property/PropertyView.css";

const API = process.env.REACT_APP_API;

export default function SavedSearch() {
  const [loading, setLoading] = useState(true);
  const [savedSearch, setSavedSearch] = useState([]);
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const refreshPage = () => {
    // console.log("saved searches")
    Axios.get(`${API}/api/v1/property/savedsearch/${uId}`)
      .then((res) => {
        setSavedSearch(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.warn(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    refreshPage();
  }, []);

  return (
    <>
  {loading ? (
    <div className="Estate_component">
      {Array.from(new Array(6)).map((_, index) => (
        <div key={index} className="Card_wrapper">
          <Skeleton variant="rectangular" width={300} height={100} />
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={50} height={20} />
        </div>
      ))}
    </div>
  ) : (
    <div className="Estate_component">
      {savedSearch.length === 0 ? (
        <div style={{ display: 'flex', alignItems: 'center', height: '60vh'}}>
          <h2>Not found any saved searches</h2>
        </div>
      ) : (
        savedSearch.map((s) => (
          <div key={s._id.toString()} className="Card_wrapper">
            <SearchCard searchDetails={s} uId={uId} refreshPage={refreshPage} />
          </div>
        ))
      )}
    </div>
  )}
</>

  );
}

