// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.New_Estate_component {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: auto;
    padding-left: 30px;
  }
  .New_Card_wrapper {
    margin: 7px;
  }
  .New_Card__text {
    text-decoration: none;
  }

  @media screen and (max-width: 1300px) {
    .New_Estate_component {
      justify-content: center;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/realestate/property/EstateDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,WAAW;EACb;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,uBAAuB;IACzB;EACF","sourcesContent":[".New_Estate_component {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: left;\n    margin: auto;\n    padding-left: 30px;\n  }\n  .New_Card_wrapper {\n    margin: 7px;\n  }\n  .New_Card__text {\n    text-decoration: none;\n  }\n\n  @media screen and (max-width: 1300px) {\n    .New_Estate_component {\n      justify-content: center;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
