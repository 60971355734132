import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";
import Card from "../../common/MediaCard/Card";
import SearchResultsCard from "../../common/MediaCard/SearchResultsCard";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import "./SearchResults.css";

const API = process.env.REACT_APP_API;
const ITEMS_PER_PAGE = 6;

export default function SearchResults({
  searchedValue,
  properties,
  setProperties,
}) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [savedProperties, setSavedProperties] = useState([]);
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    fetchSavedProperties();
    // console.log("search value: ",searchedValue);
    if (searchedValue !== null && searchedValue !== "null") {
      // console.log("searchvalue loading");
      searchProperties();
    } else {
      // console.log("Default loading:");
      defaultSearch();
    }
  }, [searchedValue, location.search]);

  const searchProperties = async () => {
    try {
      // Parse the filter parameters from the location object (URL)
      const searchParams = new URLSearchParams(location.search);
      const minPrice = searchParams.get("minPrice");
      const maxPrice = searchParams.get("maxPrice");
      const bedRooms = searchParams.get("bedRooms");
      const bathRooms = searchParams.get("bathRooms");
      const homeType = searchParams.get("homeType");
      // console.log(searchedValue)

      const res = await axios.get(
        `${API}/api/v1/property/search/${searchedValue}?minPrice=${
          minPrice || ""
        }&maxPrice=${maxPrice || ""}&bedRooms=${bedRooms || ""}&bathRooms=${
          bathRooms || ""
        }&homeType=${homeType || ""}`
      );
      const data = res.data;

      if (data.success) {
        // console.log("filterd Data :", data.data)
        setProperties(data.data);
        fetchSavedProperties();
      } else {
        setProperties([]);
      }
    } catch (error) {
      console.warn(error);
      setProperties([]);
    } finally {
      setLoading(false);
    }
  };

  const defaultSearch = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const minPrice = searchParams.get("minPrice");
      const maxPrice = searchParams.get("maxPrice");
      const bedRooms = searchParams.get("bedRooms");
      const bathRooms = searchParams.get("bathRooms");
      const homeType = searchParams.get("homeType");

      if (
        (minPrice !== null && minPrice !== "" && !isNaN(parseInt(minPrice))) ||
        (maxPrice !== null && maxPrice !== "" && !isNaN(parseInt(maxPrice))) ||
        (bedRooms !== null && bedRooms !== "" && !isNaN(parseInt(bedRooms))) ||
        (bathRooms !== null &&
          bathRooms !== "" &&
          !isNaN(parseInt(bathRooms))) ||
        (homeType !== null && homeType !== "")
      ) {
        const res = await axios.post(`${API}/api/v1/property/filter`, {
          minPrice,
          maxPrice,
          bedRooms,
          bathRooms,
          homeType,
        });
        // console.log("Server Response 02:", res.data);

        setProperties(res.data.data);
        fetchSavedProperties();
      } else {
        // Get the user's current location using the Geolocation API
        const userLocation = await getUserLocation();

        // If the user's location is the default one, fetch all properties
        if (
          userLocation.latitude === 7.69398 &&
          userLocation.longitude === 80.67592
        ) {
          const res = await axios.get(`${API}/api/v1/property/`);

          if (res.data.success) {
            setProperties(res.data.data);
          } else {
            setProperties([]);
          }
        } else {
          // If the user's location is available, fetch properties within a 25km radius
          const res = await axios.get(
            `${API}/api/v1/property/location/nearby/`,
            {
              params: {
                latitude: userLocation.latitude,
                longitude: userLocation.longitude,
                radius: 25, // Specify the radius in kilometers
              },
            }
          );

          if (res.data.success) {
            setProperties(res.data.data);
          } else {
            setProperties([]);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setProperties([]);
    } finally {
      setLoading(false);
    }
  };

  // Function to get the user's current location
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Check if the obtained coordinates are within Sri Lanka's bounds
            if (isInSriLanka(latitude, longitude)) {
              resolve({ latitude, longitude });
            } else {
              // If not in Sri Lanka, resolve with default coordinates
              resolve({ latitude: 7.69398, longitude: 80.67592 });
            }
          },
          (error) => {
            // If the user denies geolocation, resolve with default coordinates silently
            if (error.code === 1) {
              resolve({ latitude: 7.69398, longitude: 80.67592 });
            } else {
              // If there's any other error, log it and resolve with default coordinates
              console.error("Error getting location:", error);
              resolve({ latitude: 7.69398, longitude: 80.67592 });
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        resolve({ latitude: 7.69398, longitude: 80.67592 });
      }
    });
  };

  // Function to check if coordinates are within Sri Lanka's bounds
  const isInSriLanka = (latitude, longitude) => {
    // Define the bounds of Sri Lanka
    const sriLankaBounds = {
      minLatitude: 5.9255,
      maxLatitude: 9.7965,
      minLongitude: 79.5225,
      maxLongitude: 81.8779,
    };

    return (
      latitude >= sriLankaBounds.minLatitude &&
      latitude <= sriLankaBounds.maxLatitude &&
      longitude >= sriLankaBounds.minLongitude &&
      longitude <= sriLankaBounds.maxLongitude
    );
  };

  const fetchSavedProperties = () => {
    if (uId != null) {
      axios
        .get(`${API}/api/v1/user/savedproperty/${uId}`)
        .then((res) => {
          setSavedProperties(res.data.data);
        })
        .catch((error) => {
          console.error("Error fetching saved properties:", error);
        });
    }
  };

  // Calculate the total number of pages
  const totalPages = properties
    ? Math.ceil(properties.length / ITEMS_PER_PAGE)
    : 0;

  // Get the properties for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const propertiesForCurrentPage = properties
    ? properties.slice(startIndex, endIndex)
    : [];
  // console.log("prop:",properties)

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="search-results-container">
      {loading ? (
        <div className="SR_Estate_component">
          {Array.from(new Array(ITEMS_PER_PAGE)).map((_, index) => (
            <div key={index} className="SR_Card_wrapper">
              <Skeleton variant="rectangular" width={330} height={230} />
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={150} height={20} />
            </div>
          ))}
        </div>
      ) : (
        <div className="SR_Estate_component">
          {propertiesForCurrentPage.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "60vh",
              }}
            >
              <h1>Real Estate & Homes For Sale</h1>
              <br />
              <h3>No matching results. Try modifying your search.</h3>
            </div>
          ) : (
            propertiesForCurrentPage.map((property) => (
              <div key={property._id.toString()} className="SR_Card_wrapper">
                {uId ? (
                  <SearchResultsCard
                    propertyDetails={property}
                    uId={uId}
                    savedProperties={savedProperties}
                  />
                ) : (
                  <Card propertyDetails={property} />
                )}
              </div>
            ))
          )}
        </div>
      )}

      {totalPages > 1 && (
        <div className="pagination__container">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              color="primary"
              onChange={handlePageChange}
              size="large"
            />
          </Stack>
        </div>
      )}
    </div>
  );
}
