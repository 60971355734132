// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .SR_Estate_component {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 0px;
    padding-left: 10px;
    /* margin: auto; */
  }
  .SR_Card_wrapper {
    margin: 5px;
  }
  .SR_Card__text {
    text-decoration: none;
  }
  .pagination__container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: auto;
  }
  .img__message{
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
  .search-results-container {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
  }
  
  @media screen and (max-width: 800px) {
    .search-results-container {
      max-height: calc(100vh - 250px);
      overflow-y: scroll;
    }
  }

  @media screen and (max-width: 1100px) {
    .SR_Estate_component {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/realestate/searchProperty/SearchResults.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;EACpB;EACA;IACE,WAAW;EACb;EACA;IACE,qBAAqB;EACvB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;EACA;IACE,+BAA+B;IAC/B,kBAAkB;EACpB;;EAEA;IACE;MACE,+BAA+B;MAC/B,kBAAkB;IACpB;EACF;;EAEA;IACE;MACE,aAAa;MACb,uBAAuB;MACvB,mBAAmB;IACrB;EACF","sourcesContent":["  .SR_Estate_component {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: left;\n    margin-top: 0px;\n    padding-left: 10px;\n    /* margin: auto; */\n  }\n  .SR_Card_wrapper {\n    margin: 5px;\n  }\n  .SR_Card__text {\n    text-decoration: none;\n  }\n  .pagination__container {\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n    margin-top: auto;\n  }\n  .img__message{\n    display: flex;\n    justify-content: center;\n    margin-top: auto;\n  }\n  .search-results-container {\n    max-height: calc(100vh - 160px);\n    overflow-y: scroll;\n  }\n  \n  @media screen and (max-width: 800px) {\n    .search-results-container {\n      max-height: calc(100vh - 250px);\n      overflow-y: scroll;\n    }\n  }\n\n  @media screen and (max-width: 1100px) {\n    .SR_Estate_component {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
