import React, { useState,useEffect } from "react";
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Axios from 'axios';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import MapOSM from "../../realestate/map/MapOSM";
import PropertyCard from "../../common/MediaCard/PropertyCard";
import InActivePropertyCard from "../../common/MediaCard/InActivePropertyCard";
import Pagination from "@mui/material/Pagination";
import ReviewAndRating from './ReviewAndRating';
import Rating from "@mui/material/Rating";
import {
  FormControl,
  TextField,
  Button,
  Snackbar,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import "./AgentProfile.css";

const API = process.env.REACT_APP_API;
const INACTIVEITEMS_PER_PAGE = 4;
const ACTIVEITEMS_PER_PAGE = 4;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AgentProfile() {

  const { agentId } = useParams();
  const [agent, setAgent] = useState(null);
  const [clientName, setClientName] = useState("");
  const [useremail, setUseremail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [activeProperties, setActiveProperties] = useState([]);
  const [inactiveProperties, setInActiveProperties] = useState([]);
  const [loginError, setLoginError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [currentInactivePage, setCurrentInactivePage] = useState(1);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${API}/api/v1/user/agentprofile/${agentId}`)
      .then(response => {
        setAgent(response.data.data.agent);
        fetchActiveProperty();
        fetchInActiveProperty();
      })
      .catch(error => {
        console.error(error);
      });
  }, [agentId]);

  const fetchActiveProperty = async () => {
    try {
      const response = await Axios.get(`${API}/api/v1/property/active/${agentId}`);
      setActiveProperties(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInActiveProperty = async () => {
    try {
      const response = await Axios.get(`${API}/api/v1/property/inactive/${agentId}`);
      setInActiveProperties(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const sendMail=() => {
    setSubmitted(true);
    setLoginError("");

    if (!isValidName(clientName) || !isValidEmail(useremail) || !isValidMessage(message)) {
      setLoginError("Invalid Input");
      return;
    }

    const requestBody = {
      id:agentId,
      clientName: clientName,
      useremail: useremail,
      message: message,
    };

    // console.log(requestBody);

    Axios.post(`${API}/api/v1/user/message`, requestBody)
      .then((response) => {
        if (response) {      
          setOpenAlert(true);

          setClientName("");
          setUseremail("");
          setMessage("");
          setLoginError("");
          // console.log("send the email");
        } else {
          console.error("Invalid response:", response);
          setLoginError("Error occured");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error error:", error.response.data);
        setLoginError("An error occurred. Please try again.");
      });
  }
//Active properties
  const totalActivePages = activeProperties ? Math.ceil(activeProperties.length / ACTIVEITEMS_PER_PAGE) : 0;
  const startAIndex = (currentActivePage - 1) * ACTIVEITEMS_PER_PAGE;
  const endAIndex = startAIndex + ACTIVEITEMS_PER_PAGE;
  const activePropertiesForCurrentPage = activeProperties ? activeProperties.slice(startAIndex, endAIndex) : [];

  const handleAPageChange = (event, page) => {
    setCurrentActivePage(page);
  };

  //Inactive properties
  const totalInActivePages = inactiveProperties ? Math.ceil(inactiveProperties.length / INACTIVEITEMS_PER_PAGE) : 0;
  const startIndex = (currentInactivePage - 1) * INACTIVEITEMS_PER_PAGE;
  const endIndex = startIndex + INACTIVEITEMS_PER_PAGE;
  const inactivePropertiesForCurrentPage = inactiveProperties ? inactiveProperties.slice(startIndex, endIndex) : [];

  const handleINPageChange = (event, page) => {
    setCurrentInactivePage(page);
  };

  //validations
  const isValidName = (clientName) => {
    return typeof clientName === 'string' && clientName.trim().length > 0;
  };
  const isValidMessage = (message) => {
    return typeof message === 'string' && message.trim() !== '';
  };
  const isValidEmail = (useremail) => {
    return /\S+@\S+\.\S+/.test(useremail);
  };

  return (
    <div className='agent-profile-container'>
      {!agent ? (
          <div className='agent-profile-details'>
            <Box display="flex" alignItems="center">
              <Box className="agent_details">
                <Skeleton variant="circular" className="profile_avater" />
              </Box>
              <Box>
                <Skeleton variant="text" width={200} height={20} />
                <Skeleton variant="text" width={150} height={20} />
                <Skeleton variant="text" width={150} height={20} />
                <Skeleton variant="text" width={150} height={20} />
              </Box>
            </Box>
            <br/>
            <Skeleton variant="text" width="50%" height={30} />
            <br/>
            <Skeleton variant="text" width="90%" height={20} />
            <Skeleton variant="text" width="90%" height={20} />
            <Skeleton variant="text" width="90%" height={20} />
            <br/>
            <Skeleton sx={{ width: '100%', height: 290 }} animation="wave" variant="rectangular" />
          </div>
        ) : (
        <div className='agent-profile-details'>
          <Box display="flex" alignItems="center">
            <Box className="agent_details">
              <Avatar src={agent.profilePicture} className="profile_avater" />
            </Box>
            <Box>
              <h3>{agent.firstName} {agent.lastName}</h3>
              <Rating name="read-only" value={agent.averageRating} readOnly />
              <Typography gutterBottom variant="body2" component="div">
              {agent.reviews.length} Reviews
              </Typography>
                <Typography gutterBottom variant="body2" component="div">
                  Mobile No: {agent.mobileNumber}
                </Typography>
                <Typography gutterBottom variant="body2" component="div">
                  {agent.agentLocation}
                </Typography>
            </Box>
          </Box>
          <h2>About Me</h2>
          <p>{agent.description}</p>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={350} />
            ): (
            <MapOSM properties={activeProperties} />
          )}
          <div>
            <br/>
            <div className="property-card-container">
              {activePropertiesForCurrentPage.length > 0 ? (
                <>
                  <h2>Active Properties</h2>
                  {activePropertiesForCurrentPage.map((property) => (
                    <PropertyCard key={property._id} property={property} />
                  ))}
                </>
                ) : (
                  <Typography variant="body1">No active properties were found</Typography>
                  // <Typography variant="body1">Not found any active properties</Typography>
                )}
            </div>
              {totalActivePages > 1 && (
                <div className="pagination_container">
                  <Pagination
                    className="custom_pagination"
                    count={totalActivePages}
                    page={currentActivePage}
                    variant="outlined"
                    onChange={handleAPageChange}
                    size="large"
                  />
                </div>
              )}
          </div>
        </div>
      )}
      <div className='agent-profile-form'>
        <Box>
          <h2>Contact Me</h2>
          <FormControl className="dataformControl_agent" style={{ marginBottom: "10px" }}>
                <TextField
                  id="outlined-basic-name"
                  label="Name *"
                  variant="outlined"
                  value={clientName}
                  onChange={(event) => {
                    setClientName(event.target.value);
                    setLoginError("");
                  }}
                />
            </FormControl>

            <FormControl className="dataformControl_agent" style={{ marginBottom: "10px" }}>
              <TextField
                id="outlined-basic-email"
                label="Email *"
                variant="outlined"
                value={useremail}
                onChange={(event) => {
                  setUseremail(event.target.value);
                  setLoginError("");
                }}
              />
            </FormControl>
            <FormControl className="dataformControl_agent" style={{ marginBottom: "10px" }}>
            <TextField
              id="outlined-multiline-static-mg"
              label="Message *"
              variant="outlined"
              multiline
              rows={4}
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
                setLoginError("");
              }}
            />
          </FormControl>
        </Box>
        <Button variant="contained" className="btn" onClick={sendMail}>Send</Button>
        <div>
        {loginError && (
            <FormHelperText style={{ color: "#D30A0A", fontSize: "14px" }}>{loginError}</FormHelperText>
          )}
        </div>
        <br/><br/>
        <h2>Write a Review</h2>
        <ReviewAndRating aId={agentId}/>
        <br/>
        <div className="property-card-container">
        {inactivePropertiesForCurrentPage.length > 0 ? (
          <>
            <h2>Inactive Properties</h2>
            {inactivePropertiesForCurrentPage.map((property) => (
              <InActivePropertyCard key={property._id} property={property} />
            ))}
          </>
        ) : (
          <Typography variant="body1">No Inactive properties were found</Typography>
        )}
      </div>
      {totalInActivePages > 1 && (
            <div className="pagination_container">
              <Pagination
                className="custom_pagination"
                count={totalInActivePages}
                page={currentInactivePage}
                variant="outlined"
                onChange={handleINPageChange}
                size="large"
              />
            </div>
          )}
    </div>
    <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfully sent!
        </Alert>
      </Snackbar>
    </div>
  );
}

