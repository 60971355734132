import React, { useState } from "react";
import Axios from "axios";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { Link } from "react-router-dom";
import "./Form.css"

const API = process.env.REACT_APP_API;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    minWidth: '600px',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paper': {
      minWidth: '380px',
    },
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold", textAlign: "center", }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ForgotPassword({ 
  openForgotPassword,
  handleCloseForgotPassword,
  onSendSuccess,
}) {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");

  const handleSendPasswordResetLink = () => {
    setSubmitted(true);
    setLoginError("");

    if (!isValidEmail(email)) {
      // console.log("Invalid email");
      return;
    }
    // console.log(email)
    Axios.post(`${API}/api/v1/user/forgotpassword`, {email})
      .then((response) => {
        // console.log(response.data)
        if (response) {          

          setEmail("");
          setLoginError("");
          // console.log("send the changed pwd link");
          if (typeof onSendSuccess === "function") {
            onSendSuccess();
          }
        } else {
          console.error("Invalid response:", response);
          setLoginError("Error occurred while sending the password reset link. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Registration error:", error.response.data);
        setLoginError("User does not exist");
      });
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <>
    <BootstrapDialog
      onClose={handleCloseForgotPassword}
      aria-labelledby="customized-dialog-title"
      open={openForgotPassword}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseForgotPassword}>
        Forgot Password?
      </BootstrapDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          {/* Enter your email address and we'll send you a link to set your password. */}
          Enter us your email, and we'll send you a link to set your password.
        </Typography>
        <div className="login__form">
          <FormControl className="formControl" style={{ marginBottom: "10px" }}>
            <TextField
              id="outlined-basic-email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setLoginError("");
              }}
              helperText={submitted && !isValidEmail(email) ? "Invalid email" : ""}
              error={submitted && !isValidEmail(email)}
            />
          </FormControl>
          <div>
          {loginError && (
              <FormHelperText style={{ color: "#D30A0A", fontSize: "14px", textAlign: "center" }}>{loginError}</FormHelperText>
            )}
          </div>

          <Button
            variant="contained"
            size="large"
            style={{
              height: "40px",
              width: "90%",
              marginTop: "20px",
            }}
            onClick={handleSendPasswordResetLink}
          >
            Send
          </Button>
        </div>
      </DialogContent>
    </BootstrapDialog>
    </>
    
  );
}
