import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import FormHelperText from '@mui/material/FormHelperText';
import Axios from "axios";
import jwt from "jwt-decode";
import './Property.css';

const API = process.env.REACT_APP_API;

export default function Property({userInfo}) {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;
  
  const accountSettings = () => {
    navigate(`/accountsettings/${uId}`, {
      state: {
        user: userInfo,
      },
    });
  };

  const agentaccount = () => {
    navigate(`/agentProfileSettings/${uId}`, {
      state: {
        user: userInfo,
      },
    });
  }

  const handleSendButton = () => {

    const requestBody = {
      address: "ABC Test rd",
      location: {
        latitude: 6.927079,
        longitude: 79.861244
      },
      city: "Kandy",
      district: "Kandy",
      zip: "00340",
      price: 6000000,
      description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      sqft: 2000,
      bedrooms: 3,
      bathrooms: 2,
      propertyType: "House",
      nearby: ["Thomas Middle School", "Kandy City Center"],
      images: [
          "https://www.moldexrealty.ph/wp-content/uploads/2018/09/ADRIANA-House-Model-5-10-18.jpg",
          "https://www.abiinteriors.co.nz/wp-content/uploads/Balanced-and-symmetrical-kitchen-in-modern-home-explains-the-importance-of-7-principles-of-interior-design-1536x1018.jpg?format=webp",
          "https://static.asianpaints.com/content/dam/asianpaintsbeautifulhomes/home-decor-advice/design-and-style/nine-small-house-interior-design-hacks/two-seater-couches-and-wall-mounts-design-hack.jpg"
      ],
      postedBy: uId,
    };

    // console.log(requestBody);

    Axios.post(`${API}/api/v1/property/`, requestBody)
      .then((response) => {
        // console.log(response.data)
        if (response) {
          // console.log("Successfully Posted your property");
        } else {
          console.error("Invalid response:", response);
          setLoginError("An error occurred during registration. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        // console.log(error.response.data.msg);
        if (error.response.data.msg === "User does not exist") {
          setLoginError("Before listing a property, you need to SignIn");
        } else if (error.response.data.msg === "Mobile number not verified") {
          if (userInfo.role === "Agent") {
            // console.log(userInfo.role)
            setLoginError(
              <div
                onClick={agentaccount}
                style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              >
                  Please verify your MobileNumber
              </div>
            );
          } else {
            setLoginError(
              <div
                onClick={accountSettings}
                style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              >
                Please verify your MobileNumber
              </div>
            );
          }       
        }else if (error.response.data.msg === "Empty location") {
          setLoginError(
            <div
              onClick={agentaccount}
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            >
                Please enter agent's location
            </div>
          );
          
        }else {
          setLoginError("An error occurred. Please try again.");
        }

      });
  };
  
  return (
    <div className="upload__page">
      <h1>Sell your home yourself</h1>
      <p className="uploadnotice__content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet est eu purus viverra fermentum id eu urna.
      </p>
      <Button variant="contained" onClick={handleSendButton}>
        Submit
      </Button>
      <div>
      {loginError && (
          <FormHelperText style={{ color: "#D30A0A", fontSize: "14px", textAlign: "center" }}>
            <br />
            {loginError}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
