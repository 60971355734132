// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    border-radius: 8px;
    max-width: 700px;
    margin: 0 auto;
  }
  .second__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  
.dataformControl {
    width: 80% !important;
  }
.err__message {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  color: "#D30A0A";
}
.image_container {
    max-width: 200%;
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }
  
  .preview_image {
    width: calc(33% - 10px);
    margin: 5px;
  }
  .remove_icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
  }
  .text{
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
  .dataformControl {
    width: 90% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/realestate/sell/PropertyListing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;EAChB;;AAEF;IACI,qBAAqB;EACvB;AACF;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;IACI,eAAe;IACf,aAAa;IACb,eAAe;IACf,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,0CAA0C;EAC5C;EACA;IACE,eAAe;EACjB;EACA;EACA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".data__form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 25px;\n    border-radius: 8px;\n    max-width: 700px;\n    margin: 0 auto;\n  }\n  .second__form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0 auto;\n  }\n  \n.dataformControl {\n    width: 80% !important;\n  }\n.err__message {\n  font-size: 20px;\n  text-align: center;\n  margin-top: 10px;\n  color: \"#D30A0A\";\n}\n.image_container {\n    max-width: 200%;\n    display: flex;\n    flex-wrap: wrap;\n    margin: -5px;\n  }\n  \n  .preview_image {\n    width: calc(33% - 10px);\n    margin: 5px;\n  }\n  .remove_icon {\n    position: absolute;\n    top: 5px;\n    right: 5px;\n    background-color: rgba(255, 255, 255, 0.7);\n  }\n  .text{\n    font-size: 12px;\n  }\n  @media screen and (max-width: 600px) {\n  .dataformControl {\n    width: 90% !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
