import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import AgentLocationMap from "../../realestate/map/AgentLocationMap";
import "./CardAgent.css";

export default function CardAgent({ agent }) {
  const navigate = useNavigate();

  const agentInfor = () => {
    navigate(`/agent/${agent._id}`, {
      state: {
        agent: agent,
      },
    });
  };
  return (
    <>
    <Card className="custom-card">
      <CardContent className="card-content">
        <Avatar
            alt={agent.firstName}
            src={agent.profilePicture}
            sx={{ width: 110, height: 110, marginRight: '45px' }}
        />
        <div style={{marginRight: '1em'}}>
            <Typography gutterBottom sx={{fontSize: "15px", fontWeight: 550}}>
                {agent.firstName} {agent.lastName}
            </Typography>
            <Rating name="read-only" value={agent.averageRating} readOnly />
            <Typography gutterBottom variant="body2" component="div">
              {agent.reviews.length} Reviews
            </Typography>
            <Typography gutterBottom variant="body2" component="div">
              {agent.agentLocation}
            </Typography>
        </div>
        <div style={{ marginRight: '1em' }}>
          <AgentLocationMap agentLocation={agent.agentLocation}/> 
        </div>
        <div style={{ flex: 1, minWidth: '200px', width: '500px', height: '100px', marginRight: '2em' }}>
        <Typography sx={{fontSize: '14px'}}>
          {agent.description}
        </Typography>
      </div>
        <Button  size="small" variant="outlined" onClick={agentInfor} style={{ marginRight: "1em" }}>
        More Info
        </Button>

      </CardContent>
      </Card>
    </>
  );
}
