import React from "react";
import { MapContainer, TileLayer, Marker, Popup, LayersControl } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import "./OSM.css";

const MapOSM = ({ properties }) => {
  const navigate = useNavigate();

  if (properties.length === 0) {
    return <div>No properties found</div>;
  }

  const firstProperty = properties[0];
  const center = [firstProperty.location.latitude, firstProperty.location.longitude];

  const handlePopupClick = (property) => {
    navigate(`/homeinfor/${property._id}`, { state: { property } });
  };

  const squareIcon = new L.divIcon({
    className: "square-icon",
    html: '<div class="square"></div>',
  });

  const blueSquareIcon = new L.divIcon({
    className: "square-icon blue",
    html: '<div class="square"></div>',
  });

  return (
    <MapContainer center={center} zoom={12} style={{ height: "350px" }}>
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            maxZoom={19}
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Esri Satellite Map">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            maxZoom={19}
          />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Google Satellite Map">
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
            maxZoom={21}
          />
        </LayersControl.Overlay>
        </LayersControl>
      {properties.map((property) => (
        <Marker
          key={property._id}
          position={[
            property.location.latitude,
            property.location.longitude,
          ]}
          icon={squareIcon}
          eventHandlers={{
            mouseover: (e) => {
              e.target.openPopup();
              e.target.setIcon(blueSquareIcon);
            },
            mouseout: (e) => {
              e.target.closePopup();
              e.target.setIcon(squareIcon);
            },
          }}
        >
          <Popup closeButton={false}>
            <div onClick={() => handlePopupClick(property)}>
              Rs.{property.price}
              <img
                src={property.images[0]}
                alt="properties"
                style={{ width: "100%", height: "40px" }}
              />
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapOSM;
