import React, { useState } from "react";
import Axios from "axios";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, FormHelperText, FormControl } from "@mui/material";
import "./ProfileForm.css";

const API = process.env.REACT_APP_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function OTPVerification({userId,mobileNo}) {
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [otp, setOtp] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleOpenModal = () => {
    sendOTP();
    setOpenModal(true);
    setSubmitted(false);
  };

  const sendOTP = () => {
    Axios.post(`${API}/api/v1/user/sendotp`, {mobileNo})
    .then((response) => {
      if (response) { 
        setLoginError("");
        setOpenAlert(true);
      } else {
        console.error("Invalid response:", response);
        setLoginError("Error occurred. Please try again.");
        setOpenAlert(false);
      }
    })
    .catch((error) => {
      console.error(error.response);
      // console.log(error.response.data.message)
      setLoginError("Error occurred.. Please try again.");
      setOpenAlert(false);
    });
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setOtp("");
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const verifyMobile = () => {
    setSubmitted(true);
    setLoginError("");
    
    if (!isValidOTP(otp)) {
        console.log("Enter a otp code");
        return;
      }

    // console.log(userId)
    Axios.post(`${API}/api/v1/user/verifymobile`, {userId,mobileNo,otp})
      .then((response) => {
        if (response) { 
          setLoginError("");
          setOpenAlert(true);
          handleCloseModal();
        } else {
          console.error("Invalid response:", response);
          setLoginError("Error occurred. Please try again.");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        console.error(error.response.data.message);
        if(error.response.data.message === "Invalid OTP"){
            setLoginError("Invalid OTP. Please try again.");
            setOpenAlert(false);
        }else{
            setLoginError("Error occurred. Please try again.");
            setOpenAlert(false);
        }
      });
  };

  const isValidOTP = (otp) => {
    return otp.trim().length > 0;
  };

  return (
    <div>
      <Button onClick={handleOpenModal} color="success" >
        Verify
      </Button>
      <Dialog open={openModal} onClose={handleCloseModal} >
        <DialogTitle>Verify your Mobile No</DialogTitle>
        <DialogContent>
          <p>Enter the OTP code that you recived for your mobile</p>
          <FormControl className="formControl" style={{ marginBottom: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <TextField
                id="outlined-basic-OTP"
                label="OTP"
                variant="outlined"
                value={otp}
                onChange={(event) => {
                    setOtp(event.target.value);
                    setLoginError("");
                }}
                helperText={submitted && !isValidOTP(otp) ? "Invalid OTP" : ""}
                error={submitted && !isValidOTP(otp)}
                style={{ width: "100%" }}
                />
            </div>
            </FormControl>

                {loginError && (
                    <FormHelperText style={{ color: "#D30A0A", fontSize: "14px", textAlign: "center" }}>{loginError}</FormHelperText>
                )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={verifyMobile} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successful!
        </Alert>
      </Snackbar>
    </div>
  );
}
