import React from "react";
import { Link } from "react-router-dom";
import { Grid, Card, CardContent } from "@mui/material";
import "./Sell.css";
import "../sell/Property.css";

export default function Sell() {
  return (
    <div>
      <div class="sell__inforsection">
        <h1 className="sell_Infoheader">Sell your home with ease.</h1>
        <p>SriLankaHome is the #1 place to sell your home.</p>
      </div>
      <h1 className="sell__headers">Our Services</h1>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        style={{ flexWrap: "wrap" }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/findagent/" className="card__link">
            <Card className="custom__card" style={{ borderRadius: "20px" }}>
              <CardContent>
                <img
                  src={require("../../assets/icons/support.png")}
                  className="card__image"
                />
                <h4 className="card__text">Find an agent</h4>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/sell/propertyListing" className="card__link">
            <Card className="custom__card" style={{ borderRadius: "20px" }}>
              <CardContent>
                <img
                  src={require("../../assets/icons/house.png")}
                  className="card__image"
                />
                <h4 className="card__text">Advertising your listing</h4>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link to="/mypostings" className="card__link">
            <Card className="custom__card" style={{ borderRadius: "20px" }}>
              <CardContent>
                <img
                  src={require("../../assets/icons/shop.png")}
                  className="card__image"
                />
                <h4 className="card__text">Close your home sale</h4>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
