import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FormControl,
  TextField,
  Button,
  Snackbar,
  Divider,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { MuiTelInput } from 'mui-tel-input';
import MuiAlert from "@mui/material/Alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import Axios from "axios";
import "./Form.css";

const API = process.env.REACT_APP_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function RegistrationForm({onRegisterSuccess}) {
  const defaultCountryCode = '+94';
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [agentLocation, setAgentLocation] = useState("");
  const [mobileNumber, setMobileNumber] = useState(defaultCountryCode);
  const [openAlert, setOpenAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const termsAndConditionsUrl = "/termsandconditions";


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmPwd = () => setShowConfirmPassword((show) => !show);

  const handleConfirmPwdMouseDown = (event) => {
    // console.log("hi")
    event.preventDefault();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleMobileNoChange = (newValue) => {
    setMobileNumber(newValue);
    setLoginError("");
  }
  const handleRegistration = () => {

    setSubmitted(true);
    setLoginError("");

    if (!isValidFirstName(firstName)) {
      console.log("Enter correct Firstname");
      return;
    }
    if (!isValidLastName(lastName)) {
      console.log("Enter correct Lastname");
      return;
    }
    if (!isValidRole(role)) {
      console.log("Select a role");
      return;
    }
    if(role === 'Agent') {
      if (!isValidCity(agentLocation)) {
        console.log("Enter correct city");
        return;
      }
   }
    if (!isValidEmail(email)) {
      console.log("Invalid email");
      return;
    }
    if (!isValidPassword(password)) {
      console.log("Enter strong password");
      return;
    }
    if (!isMatchingPassword(password, cpassword)) {
      console.log("Password mismatch");
      return;
    }
    if (!isValidPhoneNumber(mobileNumber)) {
      console.log("Invalid phone number");
      return;
    }
    if (!termsAccepted) {
      console.log("Please accept the terms and conditions");
      return;
    }

    // Construct the request body
    const requestBody = {
      firstName: firstName,
      lastName: lastName,
      role: role,
      email: email,
      agentLocation: agentLocation,
      password: password,
      mobileNumber: mobileNumber,
    };

    // console.log(requestBody);

    Axios.post(`${API}/api/v1/user/register`, requestBody)
      .then((response) => {
        // console.log(response.data)
        if (response) {
          localStorage.setItem("token", response.data.token);
          // console.log(response.data.token);
          
          const useremail = response.data.user.email;
          const id = response.data.user.id;
          // console.log(useremail);
          // console.log(id)
          setOpenAlert(true);

          setFirstName("");
          setLastName("");
          setRole("");
          setAgentLocation("");
          setEmail("");
          setPassword("");
          setMobileNumber(defaultCountryCode);
          setLoginError("");
          // console.log("User registered");
          if (typeof onRegisterSuccess === "function") {
            onRegisterSuccess(useremail,id);
          }
        } else {
          console.error("Invalid response:", response);
          setLoginError("Already exists the user");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Registration error:", error.response.data);
        setLoginError("An error occurred during registration. Please try again.");
        // setLoginError(error.response.msg);
      });
  };
  const isValidTermsAccepted = (termsAccepted) => {
    return termsAccepted;
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPassword = (password) => {
    // Password validation (at least 8 characters with uppercase, lowercase, number, and special character)
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%#*?&]{8,}$/.test(
      password
    );
  };
  const isMatchingPassword = (password, cpassword) => {
    return password === cpassword;
  };  

  const isValidFirstName = (firstName) => {
    return typeof firstName === 'string' && firstName.trim().length > 0;
  };

  const isValidLastName = (lastName) => {
    return typeof lastName === 'string' && lastName.trim().length > 0;
  };
  
  const isValidRole = (role) => {
    return typeof role === 'string' && role.trim() !== '';
  };  
  const isValidCity = (agentLocation) => {
    return typeof agentLocation === 'string' && agentLocation.trim().length > 0;
  };
  // const isValidPhoneNumber = (mobileNumber) => {
  //   return mobileNumber.trim().length > 0;
  // };
  const isValidPhoneNumber = (mobileNumber) => {
    // Remove the country code and check if the remaining value is not empty
    const phoneNumberWithoutCountryCode = mobileNumber.replace(defaultCountryCode, '');
    return phoneNumberWithoutCountryCode.trim().length > 0;
  };
  

  return (
    <div className="login__form">
      <FormControl className="formControl" style={{ marginBottom: "10px" }}>
        <TextField
          id="outlined-basic-firstName"
          label="First Name"
          variant="outlined"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
            setLoginError("");
          }}
          // Use helperText for showing validation message
          helperText={submitted && !isValidFirstName(firstName) ? "Invalid FirstName" : ""}
          error={submitted && !isValidFirstName(firstName)}
        />
      </FormControl>
      <FormControl className="formControl" style={{ marginBottom: "10px" }}>
        <TextField
          id="outlined-basic-lastName"
          label="Last Name"
          variant="outlined"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
            setLoginError("");
          }}
          // Use helperText for showing validation message
          helperText={submitted && !isValidLastName(lastName) ? "Invalid LastName" : ""}
          error={submitted && !isValidLastName(lastName)}
        />
      </FormControl>
      <FormControl className="formControl" style={{ marginBottom: "10px" }} error={submitted && !isValidRole(role)}>
      <InputLabel id="demo-simple-select-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={role}
          label="Role"
          onChange={(event) => setRole(event.target.value)}
        >
          <MenuItem value={"User"}>User</MenuItem>
          <MenuItem value={"Agent"}>Agent</MenuItem>
        </Select>

        <FormHelperText>
        {submitted && !isValidRole(role) ? (
          <span style={{ color: "#D30A0A" }}>
           Select a Role
          </span>
        ) : (
          ""
        )}
      </FormHelperText>
      </FormControl>
      {role === "Agent" && (
        <FormControl className="formControl" style={{ marginBottom: "10px" }}>
          <TextField
            id="outlined-basic-city"
            label="City"
            variant="outlined"
            value={agentLocation}
            onChange={(event) => {
              setAgentLocation(event.target.value);
              setLoginError("");
            }}
            // Use helperText for showing validation message
            helperText={submitted && !isValidCity(agentLocation) ? "Invalid city" : ""}
            error={submitted && !isValidCity(agentLocation)}
          />
        </FormControl>
      )}
      <FormControl className="formControl" style={{ marginBottom: "10px" }}>
        <TextField
          id="outlined-basic-email"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            setLoginError("");
          }}
          helperText={submitted && !isValidEmail(email) ? "Invalid email" : ""}
          error={submitted && !isValidEmail(email)}
        />
      </FormControl>

      <FormControl className="formControl" style={{ marginBottom: "10px" }} error={submitted && !isValidPassword(password)}>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <FormHelperText>
        {submitted && !isValidPassword(password) ? (
          <span style={{ color: "#D30A0A" }}>
            Password must be at least 8 characters long and contain uppercase, lowercase, number, and special character.
          </span>
        ) : (
          ""
        )}
      </FormHelperText>
      </FormControl>

      <FormControl className="formControl" style={{ marginBottom: "10px" }} error={submitted && !isMatchingPassword(password,cpassword)}>
        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showConfirmPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleConfirmPwd}
                onMouseDown={handleConfirmPwdMouseDown}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
          value={cpassword}
          onChange={(event) => setCPassword(event.target.value)}
        />
        <FormHelperText>
          {submitted && !isMatchingPassword(password, cpassword) ? (
            <span style={{ color: "#D30A0A" }}>
              Password mismatch
            </span>
          ) : (
            ""
          )}
        </FormHelperText>
      </FormControl>

      <FormControl className="formControl" style={{ marginBottom: '10px' }}>
        <MuiTelInput
          value={mobileNumber}
          onChange={handleMobileNoChange}
          helperText={submitted && !isValidPhoneNumber(mobileNumber) ? "Invalid Mobile Number" : ""}
          error={submitted && !isValidPhoneNumber(mobileNumber)}
        />
      </FormControl>

      <FormControl className="formControl" style={{ marginBottom: "10px" }}>
        <label>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
          />
          I agree to abide by the{" "}
          <a href={termsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
            terms and conditions
          </a>
        </label>
        {submitted && !isValidTermsAccepted(termsAccepted) && (
          <FormHelperText style={{ color: "#D30A0A" }}>
            Please accept the terms and conditions
          </FormHelperText>
        )}
      </FormControl>


      <div>
      {loginError && (
          <FormHelperText style={{ color: "#D30A0A", fontSize: "14px", textAlign: "center" }}>{loginError}</FormHelperText>
        )}
      </div>

      <Button
        variant="contained"
        size="large"
        style={{
          height: "45px",
          width: "80%",
          marginTop: "20px",
        }}
        onClick={handleRegistration}
      >
        Submit
      </Button>
      {/* <Divider />
      <p>OR Connect with:</p>
      <Button
        variant="contained"
        color="primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "80%",
          backgroundColor: "#fff",
          color: "#000",
          textTransform: "none",
        }}
      >
        <GoogleIcon sx={{ fontSize: 24 }} />
        Continue with Google
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "80%",
          backgroundColor: "#3b5998",
          color: "#fff",
          textTransform: "none",
          marginTop: "10px",
        }}
      >
        <FacebookIcon sx={{ fontSize: 24 }} />
        Continue with Facebook
      </Button> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfully registered!
        </Alert>
      </Snackbar>
    </div>
  );
}