import React, { useRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './LocationSelector.css';

const UpdateLocation = ({ lat, lng, onSelect, selectedPosition }) => {
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [manualLat, setManualLat] = useState(selectedPosition.latitude);
  const [manualLng, setManualLng] = useState(selectedPosition.longitude);

  const updateMarker = (newLat, newLng) => {
    if (markerRef.current) {
      markerRef.current.setLatLng([newLat, newLng]);
    }
  };

  const navigateToLocation = () => {
    const latInput = document.getElementById('latInput').value;
    const lonInput = document.getElementById('lonInput').value;

    const newLat = parseFloat(latInput);
    const newLng = parseFloat(lonInput);

    if (!isNaN(newLat) && !isNaN(newLng) && map) {
      onSelect(newLat, newLng);
      updateMarker(newLat, newLng);
      // Use panTo to center the map to the manually entered location
      map.panTo([newLat, newLng]);
      setManualLat(newLat.toString());
      setManualLng(newLng.toString());
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let initialLat, initialLng;

        if (selectedPosition.latitude && selectedPosition.longitude) {
          initialLat = selectedPosition.latitude;
          initialLng = selectedPosition.longitude;
        } else if (lat && lng) {
          initialLat = lat;
          initialLng = lng;
        }

        if (initialLat && initialLng && mapRef.current) {
          const newMap = L.map(mapRef.current).setView([initialLat, initialLng], 18);
          setMap(newMap);

          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(newMap);

          const marker = L.circleMarker([initialLat, initialLng], {
            color: 'red',
            fillColor: 'red',
            fillOpacity: 1,
            radius: 6
          }).addTo(newMap);

          markerRef.current = marker;


          const baseLayers = {
            'OpenStreetMap': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
          };

          const overlayLayers = {
            'Google Satellite': L.tileLayer('http://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', { maxZoom: 21 }),
            'Esri Satellite': L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', { maxZoom: 17 }),
          };

          L.control.layers(baseLayers, overlayLayers).addTo(newMap);

          newMap.on('click', (e) => {
            const { lat, lng } = e.latlng;
            onSelect(lat, lng);
            updateMarker(lat, lng);
            newMap.panTo([lat, lng]);
            setManualLat(lat.toString());
            setManualLng(lng.toString());
          });
        }
      } catch (error) {
        console.warn("Map container is already initialized.");
      }
    };

    fetchData();
  }, [lat, lng, onSelect, selectedPosition]);

  return (
    <div>
      <div style={{ position: 'relative', height: '450px', width: '100%' }} ref={mapRef}>
        <div id="map" style={{ height: '100%', width: '100%' }} />
      </div>
      <div style={{ marginTop: '2px', display: 'flex', gap: '10px' }}>
        <input
          type="number"
          placeholder="Latitude"
          id="latInput"
          value={manualLat}
          onChange={(e) => setManualLat(e.target.value)}
          style={{ height: '40px' }}
        />
        <input
          type="number"
          placeholder="Longitude"
          id="lonInput"
          value={manualLng}
          onChange={(e) => setManualLng(e.target.value)}
          style={{ height: '40px' }}
        />
        <Button sx={{ height: '40px' }} size="medium" variant="outlined" onClick={navigateToLocation}>
          Enter
        </Button>
      </div>
    </div>
  );
};

export default UpdateLocation;

// import React, { useRef, useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
// import Switch from '@mui/material/Switch';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import './LocationSelector.css';

// const UpdateLocation = ({ lat, lng, onSelect, selectedPosition }) => {
//   const [map, setMap] = useState(null);
//   const mapRef = useRef(null);
//   const markerRef = useRef(null);
//   const [satelliteLayer, setSatelliteLayer] = useState(null);
//   const [manualLat, setManualLat] = useState(selectedPosition.latitude);
//   const [manualLng, setManualLng] = useState(selectedPosition.longitude);

//   const updateMarker = (newLat, newLng) => {
//     if (markerRef.current) {
//       markerRef.current.setLatLng([newLat, newLng]);
//     }
//   };

//   const toggleSatelliteView = () => {
//     if (satelliteLayer) {
//       satelliteLayer.setOpacity(satelliteLayer.options.opacity === 1 ? 0 : 1);
//     }
//   };

//   const navigateToLocation = () => {
//     const latInput = document.getElementById('latInput').value;
//     const lonInput = document.getElementById('lonInput').value;

//     const newLat = parseFloat(latInput);
//     const newLng = parseFloat(lonInput);

//     if (!isNaN(newLat) && !isNaN(newLng) && map) {
//       onSelect(newLat, newLng);
//       updateMarker(newLat, newLng);
//       // Use panTo to center the map to the manually entered location
//       map.panTo([newLat, newLng]);
//       setManualLat(newLat.toString());
//       setManualLng(newLng.toString());
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let initialLat, initialLng;

//         if (selectedPosition.latitude && selectedPosition.longitude) {
//           initialLat = selectedPosition.latitude;
//           initialLng = selectedPosition.longitude;
//         } else if (lat && lng) {
//           initialLat = lat;
//           initialLng = lng;
//         }

//         if (initialLat && initialLng && mapRef.current) {
//           const newMap = L.map(mapRef.current).setView([initialLat, initialLng], 18);
//           setMap(newMap);

//           L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(newMap);

//           const marker = L.circleMarker([initialLat, initialLng], {
//             color: 'red',
//             fillColor: 'red',
//             fillOpacity: 1,
//             radius: 6
//           }).addTo(newMap);

//           markerRef.current = marker;

//           const esriSatelliteLayer = L.tileLayer(
//             'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//             {
//               attribution: '&copy; Esri',
//               maxZoom: 19,
//               opacity: 0,
//             }
//           ).addTo(newMap);

//           setSatelliteLayer(esriSatelliteLayer);

//           newMap.on('click', (e) => {
//             const { lat, lng } = e.latlng;
//             onSelect(lat, lng);
//             updateMarker(lat, lng);
//             newMap.panTo([lat, lng]);
//             setManualLat(lat.toString());
//             setManualLng(lng.toString());
//           });
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     fetchData();
//   }, [lat, lng, onSelect, selectedPosition]);

//   return (
//     <div>
//       <div style={{ position: 'relative', height: '450px', width: '100%' }} ref={mapRef}>
//         <div id="map" style={{ height: '100%', width: '100%' }} />
//       </div>
//       <div style={{ marginTop: '2px', display: 'flex', gap: '10px' }}>
//         <input
//           type="number"
//           placeholder="Latitude"
//           id="latInput"
//           value={manualLat}
//           onChange={(e) => setManualLat(e.target.value)}
//         />
//         <input
//           type="number"
//           placeholder="Longitude"
//           id="lonInput"
//           value={manualLng}
//           onChange={(e) => setManualLng(e.target.value)}
//         />
//         <Button sx={{ height: '40px' }} size="medium" variant="outlined" onClick={navigateToLocation}>
//           Enter
//         </Button>
//         <Button size='medium' variant="text" onClick={toggleSatelliteView} startIcon={<Switch />} />
//         <p className='pargraph'>Enable Satellite View</p>
//       </div>
//     </div>
//   );
// };

// export default UpdateLocation;
