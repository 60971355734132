import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { IconButton } from "@mui/material";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "5px",
  backgroundColor: "#F9F9F9",
  border: "1px solid #57ACED",
  "&:hover": {
    backgroundColor: "#F7F7F7",
    border: "1px solid #0081E7",
  },
  marginLeft: "50px",
  width: "70%",
  height: "35px",
  display: "flex",
  alignItems: "center",
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "85%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // right: 0,
  borderRadius: "100%",
  color: "#0081E7",
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#0081E7",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

