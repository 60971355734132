// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .Estate_component {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    padding: 20px;
  }
  
  .Card_wrapper {
    margin: 7px;
  }
  .Card__text {
    text-decoration: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/realestate/property/PropertyView.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,WAAW;EACb;EACA;IACE,qBAAqB;EACvB","sourcesContent":["  .Estate_component {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin: auto;\n    padding: 20px;\n  }\n  \n  .Card_wrapper {\n    margin: 7px;\n  }\n  .Card__text {\n    text-decoration: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
