import React, { useEffect, useState } from "react";
import Axios from 'axios';
import jwt from "jwt-decode";
import MyPropertyCard from "../../common/MediaCard/MyPropertyCard";
import Skeleton from '@mui/material/Skeleton';
import "../property/EstateDetails.css";

const API = process.env.REACT_APP_API;

export default function MyPostings() {
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savedProperties, setSavedProperties] = useState([]);
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const refreshPage = () => {
    Axios.get(`${API}/api/v1/property/mypostings/${uId}`)
      .then((res) => {
        setProperty(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchSavedProperties = () => {
    Axios.get(`${API}/api/v1/user/savedproperty/${uId}`)
      .then((res) => {
        setSavedProperties(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching saved properties:", error);
      });
  };

  useEffect(() => {
    refreshPage();
    fetchSavedProperties();
  }, []);

  return (
    <>
    <div style={{ position: 'relative', marginBottom: '20px', zIndex: 1 }}>
        <div style={{ position: 'absolute', top: -20, right: 30 }}>
          <h5 style={{ color: 'red' }}>Total Properties: {property.length}</h5>
        </div>
      </div>
      
  {loading ? (
    <div className="New_Estate_component">
      {Array.from(new Array(8)).map((_, index) => (
        <div key={index} className="New_Card_wrapper">
          <Skeleton variant="rectangular" width={340} height={230} />
          <Skeleton variant="text" width={300} height={20} />
          <Skeleton variant="text" width={250} height={20} />
          <Skeleton variant="text" width={200} height={20} />
        </div>
      ))}
    </div>
  ) : (
    <div className="New_Estate_component">
      {property.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', marginLeft: 'auto', marginRight: 'auto'}}>
          <h2>No properties found</h2>
        </div>
      ) : (
        property.map((p) => (
          <div key={p._id.toString()} className="New_Card_wrapper">
            <MyPropertyCard propertyDetails={p} refreshPage={refreshPage} uId={uId} savedProperties={savedProperties} />
          </div>
        ))
      )}
    </div>
  )}
</>

  );
}

