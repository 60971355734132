import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import InputLabel from "@mui/material/InputLabel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  FormControl,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
} from "@mui/material";
import Axios from "axios";
import jwt from "jwt-decode";
import "../sell/PropertyListing.css";
import UpdateLocation from "../map/UpdateLocation";
const { BlobServiceClient } = require("@azure/storage-blob");

const steps = ["Property Details", "Location Details", "More Details"];

const API = process.env.REACT_APP_API;
const ACCNAME = process.env.REACT_APP_AZURE_ACCOUNT_NAME;
const CONNAME = process.env.REACT_APP_AZURE_CONTAINER_NAME;
const SAS = process.env.REACT_APP_SAS;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} {...props} />;
});

export default function UpdateProperty() {
  const location = useLocation();
  const propertyDetails = location.state.property;
  // console.log(propertyDetails)
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState(propertyDetails.address);
  const [city, setCity] = useState(propertyDetails.city);
  const [district, setDistrict] = useState(propertyDetails.district);
  const [zip, setZip] = useState(propertyDetails.zip);
  const [price, setPrice] = useState(propertyDetails.price);
  const [description, setDescription] = useState(propertyDetails.description);
  const [propertyType, setPropertyType] = useState(
    propertyDetails.propertyType
  );
  const [sqft, setsqft] = useState(propertyDetails.sqft);
  const [perches, setPerches] = useState(propertyDetails.perches);
  const [bedrooms, setBedrooms] = useState(propertyDetails.bedrooms);
  const [bathrooms, setBathrooms] = useState(propertyDetails.bathrooms);
  const [images, setImages] = useState(propertyDetails.images);
  const [openAlert, setOpenAlert] = useState(false);
  const [invalidImagesAlert, setInvalidImagesAlert] = useState(false);
  const [submittedToNext, setSubmittedToNext] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(
    propertyDetails.location
  );
  const [uploadedFiles, setUploadedFiles] = useState(propertyDetails.images);
  const [hideAddress, setHideAddress] = useState(propertyDetails.hideAddress);
  const [features, setFeatures] = useState({
    SwimmingPool: propertyDetails.features.SwimmingPool,
    AC: propertyDetails.features.AC,
    ThreePhaseElectricity: propertyDetails.features.ThreePhaseElectricity,
    Water: {
      parent: propertyDetails.features.Water.parent,
      children: {
        WellWater: propertyDetails.features.Water.children.WellWater,
        PipedWater: propertyDetails.features.Water.children.PipedWater,
        HotWater: propertyDetails.features.Water.children.HotWater,
      },
    },
    Parking: {
      parent: propertyDetails.features.Parking.parent,
      children: {
        Garage: {
          OneGarage: propertyDetails.features.Parking.children.Garage.OneGarage,
          TwoGarages:
            propertyDetails.features.Parking.children.Garage.TwoGarages,
          ThreeGarages:
            propertyDetails.features.Parking.children.Garage.ThreeGarages,
        },
        OpenArea: propertyDetails.features.Parking.children.OpenArea,
      },
    },
    CCTV: propertyDetails.features.CCTV,
    SolarPower: propertyDetails.features.SolarPower,
  });

  // const [features, setFeatures] = useState({
  //   SwimmingPool: propertyDetails.features.SwimmingPool,
  //   AC: propertyDetails.features.AC,
  //   ThreePhaseElectricity: propertyDetails.features.ThreePhaseElectricity,
  //   Water: {
  //     parent: propertyDetails.features.Water.parent,
  //     children: {
  //       WellWater: propertyDetails.features.Water.children.WellWater,
  //       PipedWater: propertyDetails.features.Water.children.PipedWater,
  //       HotWater: propertyDetails.features.Water.children.HotWater,
  //     },
  //   },
  //   Parking: {
  //     parent: propertyDetails.features.Parking.parent,
  //     children: {
  //       Garage: propertyDetails.features.Parking.children.Garage,
  //       OpenArea: propertyDetails.features.Parking.children.OpenArea,
  //     },
  //   },
  //   CCTV: propertyDetails.features.CCTV,
  //   SolarPower: propertyDetails.features.SolarPower,
  // });

  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const handleFeatureChange = (
    feature,
    childFeature = null,
    subChildFeature = null
  ) => {
    setFeatures((prevFeatures) => {
      if (subChildFeature) {
        // Update sub-child checkbox state
        return {
          ...prevFeatures,
          Parking: {
            ...prevFeatures.Parking,
            children: {
              ...prevFeatures.Parking.children,
              Garage: {
                ...prevFeatures.Parking.children.Garage,
                [subChildFeature]:
                  !prevFeatures.Parking.children.Garage[subChildFeature],
              },
            },
          },
        };
      } else if (childFeature) {
        // Update child checkbox state
        return {
          ...prevFeatures,
          Parking: {
            ...prevFeatures.Parking,
            children: {
              ...prevFeatures.Parking.children,
              [childFeature]: !prevFeatures.Parking.children[childFeature],
            },
          },
        };
      } else {
        // Update regular checkbox state
        if (feature === "Water" || feature === "Parking") {
          // Handle Water and Parking features differently
          const newParentState = !prevFeatures[feature].parent;
          const updatedFeatures = {
            ...prevFeatures,
            [feature]: {
              ...prevFeatures[feature],
              parent: newParentState,
            },
          };

          // If unchecking the parent, set all children to false
          if (!newParentState) {
            Object.keys(updatedFeatures[feature].children).forEach(
              (child) => (updatedFeatures[feature].children[child] = false)
            );
          }

          return updatedFeatures;
        } else {
          // Default behavior for other features
          return {
            ...prevFeatures,
            [feature]: !prevFeatures[feature],
          };
        }
      }
    });
  };
  // const handleFeatureChange = (feature, childFeature = null) => {
  //   setFeatures((prevFeatures) => {
  //     if (childFeature) {
  //       // Update nested checkbox state
  //       return {
  //         ...prevFeatures,
  //         [feature]: {
  //           ...prevFeatures[feature],
  //           children: {
  //             ...prevFeatures[feature].children,
  //             [childFeature]: !prevFeatures[feature].children[childFeature],
  //           },
  //         },
  //       };
  //     } else {
  //       // Update regular checkbox state
  //       if (feature === "Water" || feature === "Parking") {
  //         // Handle Water and Parking features differently
  //         const newParentState = !prevFeatures[feature].parent;
  //         const updatedFeatures = {
  //           ...prevFeatures,
  //           [feature]: {
  //             ...prevFeatures[feature],
  //             parent: newParentState,
  //           },
  //         };

  //         // If unchecking the parent, set all children to false
  //         if (!newParentState) {
  //           // console.log("If unchecking the parent, set all children to false");
  //           Object.keys(updatedFeatures[feature].children).forEach(
  //             (child) => (updatedFeatures[feature].children[child] = false)
  //           );
  //         }

  //         return updatedFeatures;
  //       } else {
  //         // Default behavior for other features
  //         const updatedFeatures = {
  //           ...prevFeatures,
  //           [feature]: !prevFeatures[feature],
  //         };
  //         return updatedFeatures;
  //       }
  //     }
  //   });
  // };

  const handleFileUpload = (event) => {
    const files = event.target.files;

    const uploaded = Array.from(files);
    setUploadedFiles([...uploadedFiles, ...uploaded]);

    // Create blob URLs for display
    const blobURLs = uploaded.map((file) => URL.createObjectURL(file));
    setImages([...images, ...blobURLs]);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleNextStep = () => {
    setSubmittedToNext(true);
    setLoginError("");

    if (!isValidCity(city)) {
      console.log("Enter correct city");
      return;
    }
    if (!isValidDistrict(district)) {
      console.log("Select the district");
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleUpdate = async () => {
    setSubmitted(true);
    setLoginError("");

    if (!isValidDescription(description)) {
      console.log("Enter correct description");
      return;
    }
    if (!isValidPropertyType(propertyType)) {
      console.log("Select the PropertyType");
      return;
    }
    if (!isValidPrice(price)) {
      console.log("Enter correct price");
      return;
    }
    if (!isValidSqft(sqft)) {
      console.log("Enter correct sqft");
      return;
    }
    if (!isValidPerches(perches)) {
      console.log("Enter correct perches");
      return;
    }
    if (!isValidBedrooms(bedrooms)) {
      console.log("Enter correct bedrooms");
      return;
    }
    if (!isValidBathrooms(bathrooms)) {
      console.log("Enter correct bathrooms");
      return;
    }
    if (!isValidImages(images)) {
      console.log("Upload 3 images");
      setInvalidImagesAlert(true);
      return;
    }

    const uploadedImageUrls = [];

    const blobClientConnectionURL = `https://${ACCNAME}.blob.core.windows.net?${SAS}`;
    const blobServiceClient = new BlobServiceClient(blobClientConnectionURL);

    const containerClient = blobServiceClient.getContainerClient(CONNAME);
    const baseBlobUrl = `https://${ACCNAME}.blob.core.windows.net/${CONNAME}/`;

    try {
      console.log(uploadedFiles);
      for (const fileOrUrl of uploadedFiles) {
        if (
          typeof fileOrUrl === "string" &&
          fileOrUrl.startsWith(
            "https://propertydetails.blob.core.windows.net/realestateimages/"
          )
        ) {
          // console.log("db")
          uploadedImageUrls.push(fileOrUrl);
        } else if (fileOrUrl instanceof File) {
          // console.log("azure")
          const currentDate = new Date();
          const formattedDate =
            currentDate
              .toISOString()
              .replace(/[:.]/g, "-")
              .split("T")
              .join("-")
              .substring(0, 19) + "Z";

          // Extract the filename and extension
          const fileNameWithoutExtension = fileOrUrl.name.substring(
            0,
            fileOrUrl.name.lastIndexOf(".")
          );
          const fileExtension = fileOrUrl.name.split(".").pop();

          // Append current date and time to the blob name before the extension
          const blobName = `${fileNameWithoutExtension}_${formattedDate}.${fileExtension}`;
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);
          await blockBlobClient.uploadBrowserData(fileOrUrl, {
            blockSize: 4 * 1024 * 1024,
            concurrency: 20,
          });
          uploadedImageUrls.push(baseBlobUrl + blobName);
        } else {
          // console.log("err test")
          console.error("Invalid file type:", fileOrUrl);
        }
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      return;
    }

    // Construct the request body
    const requestBody = {
      address: address,
      location: {
        latitude: selectedPosition.latitude,
        longitude: selectedPosition.longitude,
      },
      city: city,
      district: district,
      zip: zip,
      price: price,
      description: description,
      sqft: sqft,
      perches: perches,
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      features,
      hideAddress,
      propertyType: propertyType,
      images: uploadedImageUrls,
      postedBy: uId,
    };

    Axios.put(`${API}/api/v1/property/${propertyDetails._id}`, requestBody)
      .then((response) => {
        // console.log(response.data)
        if (response) {
          setOpenAlert(true);

          setTimeout(() => {
            navigate("/mypostings");
          }, 2000);
          // console.log("Successfully updated your property");
        } else {
          setOpenAlert(false);
          console.error("Invalid response:", response);
          setLoginError(
            "An error occurred during registration. Please try again."
          );
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error:", error.response.data);
        // console.log(error.response.data.msg);
        if (
          error.response.data.msg === "Could not find a property with this ID"
        ) {
          setLoginError("Something went wrong. Please try again");
        } else {
          setLoginError("An error occurred. Please try again.");
        }
      });
  };

  const isValidCity = (city) => {
    return typeof city === "string" && city.trim().length > 0;
  };
  const isValidDistrict = (district) => {
    return typeof district === "string" && district.trim() !== "";
  };
  const isValidImages = (images) => {
    return images.length >= 3;
  };
  const isValidDescription = (description) => {
    return typeof description === "string" && description.trim() !== "";
  };
  const isValidPropertyType = (propertyType) => {
    return typeof propertyType === "string" && propertyType.trim() !== "";
  };
  const isValidPrice = (price) => {
    return price !== undefined && price >= 0;
  };
  const isValidSqft = (sqft) => {
    return sqft !== undefined && sqft >= 0;
  };
  const isValidPerches = (perches) => {
    return perches !== undefined && perches >= 0;
  };
  const isValidBedrooms = (bedrooms) => {
    return bedrooms !== undefined && bedrooms >= 0;
  };
  const isValidBathrooms = (bathrooms) => {
    return bathrooms !== undefined && bathrooms >= 0;
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedUploadedFiles = [...uploadedFiles];
    updatedUploadedFiles.splice(index, 1);
    setUploadedFiles(updatedUploadedFiles);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "40px" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep === 0 && (
          <Box className="data__form" sx={{ marginTop: "40px" }}>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-address"
                label="Address"
                variant="outlined"
                value={address}
                onChange={(event) => {
                  setAddress(event.target.value);
                  setLoginError("");
                }}
                disabled
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-city"
                label="Nearest City"
                variant="outlined"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value);
                  setLoginError("");
                }}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-zip"
                label="Postal code"
                variant="outlined"
                value={zip}
                onChange={(event) => {
                  setZip(event.target.value);
                  setLoginError("");
                }}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
              error={submittedToNext && !isValidDistrict(district)}
            >
              <InputLabel id="demo-simple-select-label-district">
                District
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-district"
                id="demo-simple-select-district"
                value={district}
                label="District"
                onChange={(event) => setDistrict(event.target.value)}
              >
                <MenuItem value={"Colombo"}>Colombo</MenuItem>
                <MenuItem value={"Gampaha"}>Gampaha</MenuItem>
                <MenuItem value={"Kalutara"}>Kalutara</MenuItem>
                <MenuItem value={"Kandy"}>Kandy</MenuItem>
                <MenuItem value={"Matale"}>Matale</MenuItem>
                <MenuItem value={"Nuwara Eliya"}>Nuwara Eliya</MenuItem>
                <MenuItem value={"Kegalle"}>Kegalle</MenuItem>
                <MenuItem value={"Ratnapura"}>Ratnapura</MenuItem>
                <MenuItem value={"Hambantota"}>Hambantota</MenuItem>
                <MenuItem value={"Matara"}>Matara</MenuItem>
                <MenuItem value={"Galle"}>Galle</MenuItem>
                <MenuItem value={"Badulla"}>Badulla</MenuItem>
                <MenuItem value={"Monaragala"}>Monaragala</MenuItem>
                <MenuItem value={"Anuradhapura"}>Anuradhapura</MenuItem>
                <MenuItem value={"Polonnaruwa"}>Polonnaruwa</MenuItem>
                <MenuItem value={"Kurunegala"}>Kurunegala</MenuItem>
                <MenuItem value={"Puttalam"}>Puttalam</MenuItem>
                <MenuItem value={"Trincomalee"}>Trincomalee</MenuItem>
                <MenuItem value={"Batticaloa"}>Batticaloa</MenuItem>
                <MenuItem value={"Ampara"}>Ampara</MenuItem>
                <MenuItem value={"Jaffna"}>Jaffna</MenuItem>
                <MenuItem value={"Kilinochchi"}>Kilinochchi</MenuItem>
                <MenuItem value={"Mannar"}>Mannar</MenuItem>
                <MenuItem value={"Mullaitivu"}>Mullaitivu</MenuItem>
                <MenuItem value={"Vavuniya"}>Vavuniya</MenuItem>
              </Select>

              <FormHelperText>
                {submittedToNext && !isValidDistrict(district) ? (
                  <span style={{ color: "#D30A0A" }}>Select a District</span>
                ) : (
                  ""
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Images
                </Button>
              </label>
            </FormControl>
            <Box className="dataformControl">
              <div className="image_container">
                {images.map((file, index) => (
                  <div key={index} className="preview_image">
                    <IconButton
                      className="remove_icon"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                    <img
                      src={file}
                      alt={`preview-${index}`}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                ))}
              </div>
            </Box>
          </Box>
        )}
        {activeStep === 1 && (
          <Box className="second__form">
            <div
              style={{ marginBottom: "10px", width: "90%", height: "550px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3 style={{ color: "rgb(60, 60, 60)", marginRight: "15px" }}>
                  Select the exact location
                </h3>
                {/* {selectedPosition.latitude && selectedPosition.longitude && (
                    <p className="text">Lat & Lng: {selectedPosition.latitude}, {selectedPosition.longitude}</p>
                )} */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hideAddress}
                      onChange={() => setHideAddress(!hideAddress)}
                    />
                  }
                  label="Hide exact location"
                />
              </div>
              <UpdateLocation
                onSelect={(latitude, longitude) => {
                  setSelectedPosition({ latitude, longitude });
                  // console.log(`Selected Location: Latitude: ${latitude}, Longitude: ${longitude}`);
                }}
                lat={propertyDetails.location.latitude}
                lng={propertyDetails.location.longitude}
                selectedPosition={selectedPosition}
              />
            </div>
          </Box>
        )}
        {activeStep === 2 && (
          <Box className="data__form" sx={{ marginTop: "40px" }}>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-multiline-static-des"
                label="Description *"
                variant="outlined"
                multiline
                rows={4}
                value={description}
                onChange={(event) => {
                  setDescription(event.target.value);
                  setLoginError("");
                }}
                helperText={
                  submitted && !isValidDescription(description)
                    ? "Enter description"
                    : ""
                }
                error={submitted && !isValidDescription(description)}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
              error={submitted && !isValidPropertyType(propertyType)}
            >
              <InputLabel id="demo-simple-select-label-propertyType">
                PropertyType *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-propertyType"
                id="demo-simple-select-propertyType"
                value={propertyType}
                label="PropertyType *"
                onChange={(event) => setPropertyType(event.target.value)}
              >
                <MenuItem value={"House"}>House</MenuItem>
                <MenuItem value={"TownHome"}>TownHome</MenuItem>
                <MenuItem value={"Apartment"}>Apartment</MenuItem>
                <MenuItem value={"Land"}>Land</MenuItem>
                <MenuItem value={"Business"}>Commercial/Business use</MenuItem>
              </Select>

              <FormHelperText>
                {submitted && !isValidPropertyType(propertyType) ? (
                  <span style={{ color: "#D30A0A" }}>
                    Select the Property Type
                  </span>
                ) : (
                  ""
                )}
              </FormHelperText>
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-price"
                label="Price *"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={price}
                onChange={(event) => {
                  setPrice(event.target.value);
                  setLoginError("");
                }}
                helperText={
                  submitted && !isValidPrice(price) ? "Invalid price" : ""
                }
                error={submitted && !isValidPrice(price)}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-sqft"
                label="sqft (Roof Area) *"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={sqft}
                onChange={(event) => {
                  setsqft(event.target.value);
                  setLoginError("");
                }}
                helperText={
                  submitted && !isValidSqft(sqft) ? "Invalid sqft" : ""
                }
                error={submitted && !isValidSqft(sqft)}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-perches"
                label="perch (Land Area) *"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={perches}
                onChange={(event) => {
                  setPerches(event.target.value);
                  setLoginError("");
                }}
                helperText={
                  submitted && !isValidPerches(perches) ? "Invalid Perches" : ""
                }
                error={submitted && !isValidPerches(perches)}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-bedrooms"
                label="Bedrooms *"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={bedrooms}
                onChange={(event) => {
                  setBedrooms(event.target.value);
                  setLoginError("");
                }}
                helperText={
                  submitted && !isValidBedrooms(bedrooms)
                    ? "Invalid number of bedrooms"
                    : ""
                }
                error={submitted && !isValidBedrooms(bedrooms)}
              />
            </FormControl>
            <FormControl
              className="dataformControl"
              style={{ marginBottom: "10px" }}
            >
              <TextField
                id="outlined-basic-bathrooms"
                label="Bathrooms *"
                variant="outlined"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={bathrooms}
                onChange={(event) => {
                  setBathrooms(event.target.value);
                  setLoginError("");
                }}
                helperText={
                  submitted && !isValidBathrooms(bathrooms)
                    ? "Invalid number of bathrooms"
                    : ""
                }
                error={submitted && !isValidBathrooms(bathrooms)}
              />
            </FormControl>
            <FormControl component="fieldset" className="dataformControl">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.SwimmingPool}
                      onChange={() => handleFeatureChange("SwimmingPool")}
                    />
                  }
                  label="Swimming Pool"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.AC}
                      onChange={() => handleFeatureChange("AC")}
                    />
                  }
                  label="Air Conditioning"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.ThreePhaseElectricity}
                      onChange={() =>
                        handleFeatureChange("ThreePhaseElectricity")
                      }
                    />
                  }
                  label="3 Phase Electricity"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.SolarPower}
                      onChange={() => handleFeatureChange("SolarPower")}
                    />
                  }
                  label="Solar Power"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.CCTV}
                      onChange={() => handleFeatureChange("CCTV")}
                    />
                  }
                  label="CCTV"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.Parking.parent}
                      onChange={() => handleFeatureChange("Parking")}
                    />
                  }
                  label="Parking"
                />
                {features.Parking.parent && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={features.Parking.children.Garage}
                          onChange={() =>
                            handleFeatureChange("Parking", "Garage")
                          }
                          size="small"
                        />
                      }
                      label={<span style={{ fontSize: "13px" }}>Garage</span>}
                    />
                    {features.Parking.children.Garage && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                features.Parking.children.Garage.OneGarage
                              }
                              onChange={() =>
                                handleFeatureChange(
                                  "Parking",
                                  "Garage",
                                  "OneGarage"
                                )
                              }
                              size="small"
                              color="default"
                            />
                          }
                          label={
                            <span style={{ fontSize: "13px" }}>One Garage</span>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                features.Parking.children.Garage.TwoGarages
                              }
                              onChange={() =>
                                handleFeatureChange(
                                  "Parking",
                                  "Garage",
                                  "TwoGarages"
                                )
                              }
                              size="small"
                              color="default"
                            />
                          }
                          label={
                            <span style={{ fontSize: "13px" }}>
                              Two Garages
                            </span>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                features.Parking.children.Garage.ThreeGarages
                              }
                              onChange={() =>
                                handleFeatureChange(
                                  "Parking",
                                  "Garage",
                                  "ThreeGarages"
                                )
                              }
                              size="small"
                              color="default"
                            />
                          }
                          label={
                            <span style={{ fontSize: "13px" }}>
                              Three Garages
                            </span>
                          }
                        />
                      </>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={features.Parking.children.OpenArea}
                          onChange={() =>
                            handleFeatureChange("Parking", "OpenArea")
                          }
                          size="small"
                        />
                      }
                      label={
                        <span style={{ fontSize: "13px" }}>Open Area</span>
                      }
                    />
                  </FormGroup>
                )}
                {/* <FormControlLabel
              control={
                <Checkbox
                  checked={features.Parking.parent}
                  onChange={() => handleFeatureChange('Parking')}
                />
              }
              label="Parking"
            />
            {features.Parking.parent && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.Parking.children.Garage}
                      onChange={() =>
                        handleFeatureChange('Parking', 'Garage')
                      }
                      size="small"
                      color="default"
                    />
                  }
                  label={<span style={{ fontSize: '13px' }}>Garage</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.Parking.children.OpenArea}
                      onChange={() =>
                        handleFeatureChange('Parking', 'OpenArea')
                      }
                      size="small"
                      color="default"
                    />
                  }
                  label={<span style={{ fontSize: '13px' }}>Open Area</span>}
                />
              </FormGroup>
            )} */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={features.Water.parent}
                      onChange={() => handleFeatureChange("Water")}
                    />
                  }
                  label="Water"
                />
                {features.Water.parent && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={features.Water.children.WellWater}
                          onChange={() =>
                            handleFeatureChange("Water", "WellWater")
                          }
                          size="small"
                          color="default"
                        />
                      }
                      label={
                        <span style={{ fontSize: "13px" }}>Well Water</span>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={features.Water.children.PipedWater}
                          onChange={() =>
                            handleFeatureChange("Water", "PipedWater")
                          }
                          size="small"
                          color="default"
                        />
                      }
                      label={
                        <span style={{ fontSize: "13px" }}>Piped Water</span>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={features.Water.children.HotWater}
                          onChange={() =>
                            handleFeatureChange("Water", "HotWater")
                          }
                          size="small"
                          color="default"
                        />
                      }
                      label={
                        <span style={{ fontSize: "13px" }}>Hot Water</span>
                      }
                    />
                  </FormGroup>
                )}
              </FormGroup>
            </FormControl>
          </Box>
        )}
        <Box className="data__form">
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {activeStep > 0 && (
              <Button
                variant="outlined"
                onClick={handlePrevStep}
                style={{ marginRight: "8px" }}
              >
                Back
              </Button>
            )}
            <Box>
              {activeStep < steps.length - 1 ? (
                <Button
                  variant="outlined"
                  onClick={handleNextStep}
                  style={{ marginLeft: "8px" }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  style={{ marginLeft: "8px" }}
                >
                  Update
                </Button>
              )}
            </Box>
          </Box>
          <div>
            {loginError && (
              <FormHelperText
                style={{
                  color: "#D30A0A",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <br />
                {loginError}
              </FormHelperText>
            )}
          </div>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
          Successfully updated!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={invalidImagesAlert}
        autoHideDuration={7000}
        onClose={() => setInvalidImagesAlert(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          <AlertTitle>Upload Images</AlertTitle>
          Please go back to the 1st step and upload at least 3 images to
          proceed.
        </Alert>
      </Snackbar>
    </Box>
  );
}
