import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import AdvancedSearch from "../advancedSearch/AdvancedSearch";
import OSM from "../map/OSM";
import Footer from "../../common/View/Footer";
import SearchResults from "./SearchResults";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "./SearchProperty.css";

const API = process.env.REACT_APP_API;

export default function SearchProperty() {
  const location = useLocation();
  const [properties, setProperties] = useState([]);
  const defaultCoordinates = [7.69398, 80.67592];
  const [searchedLocationCoordinates, setSearchedLocationCoordinates] = useState(defaultCoordinates);
  const searchParams = new URLSearchParams(location.search);
  const searchedValue = searchParams.get("address");

  const onMapChange = async (bounds) => {
    // console.log("onMapChange triggered")
    try {
      const { _northEast, _southWest } = bounds;
      const northEast = [_northEast.lat, _northEast.lng];
      const southWest = [_southWest.lat, _southWest.lng];
      // console.log(southWest)

      const minPrice = searchParams.get('minPrice');
      const maxPrice = searchParams.get('maxPrice');
      const bedRooms = searchParams.get('bedRooms');
      const bathRooms = searchParams.get('bathRooms');
      const homeType = searchParams.get('homeType');
  
      if (
        (minPrice !== null && minPrice !== '' && !isNaN(parseInt(minPrice))) ||
        (maxPrice !== null && maxPrice !== '' && !isNaN(parseInt(maxPrice))) ||
        (bedRooms !== null && bedRooms !== '' && !isNaN(parseInt(bedRooms))) ||
        (bathRooms !== null && bathRooms !== '' && !isNaN(parseInt(bathRooms))) ||
        (homeType !== null && homeType !== '')
      ) {
        console.log("filter",searchedValue)
        if (searchedValue !== null && searchedValue !== "null") {
          // console.log("with value")
          const res = await Axios.get(
            `${API}/api/v1/property/search/${searchedValue}?minPrice=${minPrice || ''}&maxPrice=${maxPrice || ''}&bedRooms=${bedRooms || ''}&bathRooms=${bathRooms || ''}&homeType=${homeType || ''}`
          );
          // console.log("Server Response 02:", res.data);
          setProperties(res.data.data);
        }else{
          const res = await Axios.post(`${API}/api/v1/property/filter`, { minPrice, maxPrice, bedRooms, bathRooms, homeType });
          // console.log(res)
          // console.log("Server Response 02:", res.data);
          setProperties(res.data.data);
        }
      }else{
          const res = await Axios.post(`${API}/api/v1/property/searchByBounds`, {
            northEast,
            southWest,
            searchedValue
          });
          if (res.data.success) {
            setProperties(res.data.data);
            // console.log(res.data.data);
          } else {
            setProperties([]);
          }
        }
    } catch (error) {
      console.warn("error:", error);
    }
  };  
  
  useEffect(() => {
    document.body.style.overflow = "hidden";
  
    const fetchSearchedLocation = async () => {
      try {
        const normalizedSearchedValue = searchedValue === "null" ? null : searchedValue;
  
        if (normalizedSearchedValue === null || normalizedSearchedValue.trim() === "") {
          // Get the user's current location using the Geolocation API
          try {
            const userLocation = await getUserLocation();
            setSearchedLocationCoordinates([userLocation.latitude, userLocation.longitude]);
          } catch (error) {
            console.error("Error getting user location:", error);
            setSearchedLocationCoordinates(defaultCoordinates);
          }
        } else {
          // Convert the location to lat & long
          const provider = new OpenStreetMapProvider();
          const results = await provider.search({ query: normalizedSearchedValue });
  
          if (results.length > 0) {
            const { y: lat, x: lng } = results[0];
            setSearchedLocationCoordinates([lat, lng]);
          } else {
            setSearchedLocationCoordinates(defaultCoordinates);
          }
        }
      } catch (error) {
        console.error("Error processing searched location:", error);
        setSearchedLocationCoordinates(defaultCoordinates);
      }
    };
  
    // Immediately invoke the asynchronous function
    fetchSearchedLocation();
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [searchedValue]);
  
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
  
            // Check if the obtained coordinates are within Sri Lanka's bounds
            if (isInSriLanka(latitude, longitude)) {
              resolve({ latitude, longitude });
            } else {
              // If not in Sri Lanka, resolve with default coordinates
              resolve({ latitude: 7.69398, longitude: 80.67592 });
            }
          },
          (error) => {
            // If the user denies geolocation, resolve with default coordinates silently
            if (error.code === 1) {
              resolve({ latitude: 7.69398, longitude: 80.67592 });
            } else {
              // If there's any other error, log it and resolve with default coordinates
              console.error("Error getting location:", error);
              resolve({ latitude: 7.69398, longitude: 80.67592 });
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        resolve({ latitude: 7.69398, longitude: 80.67592 });
      }
    });
  };
  
  // Function to check if coordinates are within Sri Lanka's bounds
  const isInSriLanka = (latitude, longitude) => {
    // Define the bounds of Sri Lanka
    const sriLankaBounds = {
      minLatitude: 5.9255,
      maxLatitude: 9.7965,
      minLongitude: 79.5225,
      maxLongitude: 81.8779,
    };
  
    return (
      latitude >= sriLankaBounds.minLatitude &&
      latitude <= sriLankaBounds.maxLatitude &&
      longitude >= sriLankaBounds.minLongitude &&
      longitude <= sriLankaBounds.maxLongitude
    );
  };

  // const getUserLocation = () => {
  //   return new Promise((resolve, reject) => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //           resolve({ latitude, longitude });
  //         },
  //         (error) => {
  //           // If the user denies geolocation, resolve with default coordinates silently
  //           if (error.code === 1) {
  //             resolve({ latitude: 7.69398, longitude: 80.67592 });
  //           } else {
  //             // If there's any other error, log it and resolve with default coordinates
  //             console.error("Error getting location:", error);
  //             resolve({ latitude: 7.69398, longitude: 80.67592 });
  //           }
  //         },
  //         {
  //           enableHighAccuracy: true,
  //           timeout: 5000,
  //           maximumAge: 0,
  //         }
  //       );
  //     } else {
  //       console.error("Geolocation is not supported by this browser.");
  //       resolve({ latitude: 7.69398, longitude: 80.67592 });
  //     }
  //   });
  // };

  return (
    <div className="container">
      <AdvancedSearch searchedValue={searchedValue} />
      <hr />
      <div className="content-wrapper">
        <div className="map-container">
          <OSM locationCoordinates={searchedLocationCoordinates} properties={properties} onMapChange={onMapChange} />
        </div>
        <div className="results-container">
          <SearchResults searchedValue={searchedValue} properties={properties} setProperties={setProperties} />
          <Footer />
        </div>
      </div>
    </div>
  );
}

//display the full SL map with properties
// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Axios from "axios";
// import AdvancedSearch from "../advancedSearch/AdvancedSearch";
// import OSM from "../map/OSM";
// import Footer from "../../common/View/Footer";
// import SearchResults from "./SearchResults";
// import { OpenStreetMapProvider } from "leaflet-geosearch";
// import "./SearchProperty.css";

// const API = process.env.REACT_APP_API;

// export default function SearchProperty() {
//   const location = useLocation();
//   const [properties, setProperties] = useState([]);
//   const defaultCoordinates = [7.69398, 80.67592];
//   const [searchedLocationCoordinates, setSearchedLocationCoordinates] = useState(defaultCoordinates);
//   const searchParams = new URLSearchParams(location.search);
//   const searchedValue = searchParams.get("address");

//   const onMapChange = async (bounds) => {
//     console.log("onMapChange triggered")
//     try {
//       const { _northEast, _southWest } = bounds;
//       const northEast = [_northEast.lat, _northEast.lng];
//       const southWest = [_southWest.lat, _southWest.lng];
//       console.log(southWest)

//       const minPrice = searchParams.get('minPrice');
//       const maxPrice = searchParams.get('maxPrice');
//       const bedRooms = searchParams.get('bedRooms');
//       const bathRooms = searchParams.get('bathRooms');
//       const homeType = searchParams.get('homeType');
  
//       if (
//         (minPrice !== null && minPrice !== '' && !isNaN(parseInt(minPrice))) ||
//         (maxPrice !== null && maxPrice !== '' && !isNaN(parseInt(maxPrice))) ||
//         (bedRooms !== null && bedRooms !== '' && !isNaN(parseInt(bedRooms))) ||
//         (bathRooms !== null && bathRooms !== '' && !isNaN(parseInt(bathRooms))) ||
//         (homeType !== null && homeType !== '')
//       ) {
//         console.log("filter",searchedValue)
//         if (searchedValue !== null && searchedValue !== "null") {
//           console.log("with value")
//           const res = await Axios.get(
//             `${API}/api/v1/property/search/${searchedValue}?minPrice=${minPrice || ''}&maxPrice=${maxPrice || ''}&bedRooms=${bedRooms || ''}&bathRooms=${bathRooms || ''}&homeType=${homeType || ''}`
//           );
//           console.log("Server Response 02:", res.data);
//           setProperties(res.data.data);
//         }else{
//           const res = await Axios.post(`${API}/api/v1/property/filter`, { minPrice, maxPrice, bedRooms, bathRooms, homeType });
//           console.log(res)
//           console.log("Server Response 02:", res.data);
//           setProperties(res.data.data);
//         }
//       }else{
//           const res = await Axios.post(`${API}/api/v1/property/searchByBounds`, {
//             northEast,
//             southWest,
//             searchedValue
//           });
//           if (res.data.success) {
//             setProperties(res.data.data);
//             console.log(res.data.data);
//           } else {
//             setProperties([]);
//           }
//         }
//     } catch (error) {
//       console.log("error:", error);
//     }
//   };  
  
//   useEffect(() => {
//     document.body.style.overflow = "hidden";

//     const normalizedSearchedValue = searchedValue === "null" ? null : searchedValue;

//     if (normalizedSearchedValue === null || normalizedSearchedValue.trim() === "") {
//       setSearchedLocationCoordinates(defaultCoordinates);      
//     } else {
//       //convert the location to lat & long
//       const provider = new OpenStreetMapProvider();
//       provider
//         .search({ query: normalizedSearchedValue })
//         .then((results) => {
//           if (results.length > 0) {
//             const { y: lat, x: lng } = results[0];
//             setSearchedLocationCoordinates([lat, lng]);
//           } else {
//             setSearchedLocationCoordinates(defaultCoordinates);
//           }
//         })
//         .catch((error) => {
//           console.error("Geocoding error:", error);
//           setSearchedLocationCoordinates(defaultCoordinates);
//         });
//     }

//     return () => {
//       document.body.style.overflow = "auto";
//     };
//   }, [searchedValue]);

//   return (
//     <div className="container">
//       <AdvancedSearch searchedValue={searchedValue} />
//       <hr />
//       <div className="content-wrapper">
//         <div className="map-container">
//           <OSM locationCoordinates={searchedLocationCoordinates} properties={properties} onMapChange={onMapChange} />
//         </div>
//         <div className="results-container">
//           <SearchResults searchedValue={searchedValue} properties={properties} setProperties={setProperties} />
//           <Footer />
//         </div>
//       </div>
//     </div>
//   );
// }