import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Search, SearchIconWrapper, StyledInputBase,SuggestionsList,SuggestionItem,ClearButton } from "./SearchStyle";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from "@mui/icons-material/Clear";
import jwt from "jwt-decode";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import "./AdvancedSearch.css"

const API = process.env.REACT_APP_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const hometypes = [
  'House',
  'TownHome',
  'Land',
  'Apartment',
];

export default function AdvancedSearch({ searchedValue }) {
  const navigate = useNavigate();
  const [value, setSearchedValue] = useState(searchedValue);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [mainPrice, setMainPrice] = useState("");
  const [homeType, setHomeType] = useState([]);
  const [bedRooms, setBedRooms] = useState();
  const [bathRooms, setBathRooms] = useState();
  const [mainRooms, setMainRooms] = useState("");
  const [menuAnchorElPrice, setMenuAnchorElPrice] = useState(null);
  const [menuAnchorElBBrooms, setMenuAnchorElBBrooms] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;
  const [openAlert, setOpenAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [minPrice, maxPrice, bedRooms, bathRooms, homeType]);

  const handleSearch = () => {
    navigate(`/search?address=${value}&minPrice=${minPrice}&maxPrice=${maxPrice}&bedRooms=${bedRooms}&bathRooms=${bathRooms}&homeType=${homeType.join(',')}`);
  };

  const handleClearSearch = () => {
    setSearchedValue("");
    setSuggestions([]);
    navigate(`/search?minPrice=${minPrice}&maxPrice=${maxPrice}&bedRooms=${bedRooms}&bathRooms=${bathRooms}&homeType=${homeType.join(',')}`);
  };
  
  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setSearchedValue(inputValue);

    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${inputValue}&format=json&countrycodes=LK`
      );
      // const suggestionNames = response.data.map(item => item.name);
      // console.log("suggestionNames:",suggestionNames);
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };
  
  const handleSelectSuggestion = (suggestion) => {
    //suggetion.display_name
    setSearchedValue(suggestion.display_name);
    setSuggestions([]);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      setSuggestions([]);
      setIsInputFocused(false);
    }
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };
  
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const handleErrorAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorAlert(false);
  };

  const handlePriceMenuOpen = (event) => {
    setMenuAnchorElPrice(event.currentTarget);
  };

  const handlePriceMenuClose = () => {
    setMenuAnchorElPrice(null);
  };

  const handleBNBroomsMenuOpen = (event) => {
    setMenuAnchorElBBrooms(event.currentTarget);
  };

  const handleBNBroomsMenuClose = () => {
    setMenuAnchorElBBrooms(null);
  };

  const handleChange_MinPrice = (event) => {
    setMinPrice(event.target.value);
    updateMainPrice(event.target.value, maxPrice);
    handleSearch();
  };

  const handleChange_MaxPrice = (event) => {
    setMaxPrice(event.target.value);
    updateMainPrice(minPrice, event.target.value);
    handleSearch();
  };

  const updateMainPrice = (min, max) => {
    if (min !== "" && max !== "") {
      setMainPrice(`Rs.${min} - ${max}`);
    } else if (min !== "") {
      setMainPrice(`Min Rs. ${min}`);
    } else if (max !== "") {
      setMainPrice(`Max Rs. ${max}`);
    } else {
      setMainPrice("");
    }
  };

  const handleChange_BedRooms = (event) => {
    setBedRooms(event.target.value);
    updateMainRooms(event.target.value, bathRooms);
    handleSearch();
  };
  
  const handleChange_BathRooms = (event) => {
    setBathRooms(event.target.value);
    updateMainRooms(bedRooms, event.target.value);
    handleSearch();
  };

  const updateMainRooms = (bedrooms, bathrooms) => {
    if (bedrooms !== "" && bathrooms !== "") {
      setMainRooms(`${bedrooms} bd, ${bathrooms} ba`);
    } else if (bedrooms !== "") {
      setMainRooms(`${bedrooms} bd`);
    } else if (bathrooms !== "") {
      setMainRooms(`${bathrooms} ba`);
    } else {
      setMainRooms("");
    }
  }; 

  const handleChange_HomeType = (event) => {
    const {
      target: { value },
    } = event;
    setHomeType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handleSearch();
  };

  const handleSaveSearch = async () => {
    const requestBody = {
      uId,
      address: value,
      minPrice,
      maxPrice,
      homeType,
      bedRooms,
      bathRooms,
    }

    axios.post(`${API}/api/v1/property/savedsearch`, requestBody)
      .then((response) => {
        if (response) {
          // console.log("Successfully saved the search details");
          setOpenAlert(true);
        } else {
          console.error("Invalid response:", response);
          // console.log("An error occurred during saving. Please try again.");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        // console.log(error.response.data.msg);
        // console.log("Please SignIn to the system");
        setOpenAlert(false);
        setOpenErrorAlert(true);
      });
   }

  return (
    <div>
      {windowWidth > 800 ? (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
              placeholder="Colombo, Sri Lanka"
              inputProps={{ "aria-label": "search" }}
              value={value}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              // onBlur={handleBlur}
              endAdornment={
                value && (
                  <ClearButton onClick={handleClearSearch} size="small">
                    <ClearIcon />
                  </ClearButton>
                )}
            />
            {/* {isInputFocused && suggestions.length > 0 && ( */}
            {isInputFocused && suggestions.length > 0 && (
              <SuggestionsList>
                {suggestions.map((suggestion) => (
                  <SuggestionItem
                    key={suggestion.place_id}
                    onClick={() => handleSelectSuggestion(suggestion)}
                  >
                    {suggestion.display_name}
                  </SuggestionItem>
                ))}
              </SuggestionsList>
            )}
          </Search>
        {/* price */}
        <>
          <Button
          aria-controls="price-menu"
          aria-haspopup="true"
          onClick={handlePriceMenuOpen}
          variant="outlined"
          className="responsive-button"
          endIcon={<ArrowDropDownIcon />}
        >
            {mainPrice ? mainPrice : "Price"}
          </Button>
          <Menu
            id="price-menu"
            anchorEl={menuAnchorElPrice}
            open={Boolean(menuAnchorElPrice)}
            onClose={handlePriceMenuClose}
          >
            <MenuItem>
            <p style={{ textAlign: "center" }}>Minimum : </p>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Min Price</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={minPrice}
                  label="Min Price"
                  onChange={handleChange_MinPrice}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={5000000}>5 mn(50 lakhs)</MenuItem>
                  <MenuItem value={10000000}>10 mn(100 lakhs)</MenuItem>
                  <MenuItem value={20000000}>20 mn(200 lakhs)</MenuItem>
                  <MenuItem value={30000000}>30 mn(300 lakhs)</MenuItem>
                  <MenuItem value={40000000}>40 mn(400 lakhs)</MenuItem>
                  <MenuItem value={50000000}>50 mn(500 lakhs)</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem>
            <p style={{ textAlign: "center" }}>Maximum : </p>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Max Price</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={maxPrice}
                  label="Max Price"
                  onChange={handleChange_MaxPrice}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={50000000}>50 mn(500 lakhs)</MenuItem>
                  <MenuItem value={100000000}>100 mn(1000 lakhs)</MenuItem>
                  <MenuItem value={500000000}>500 mn(5000 lakhs)</MenuItem>
                  <MenuItem value={1000000000}>1 bn(10000 lakhs)</MenuItem>
                  <MenuItem value={5000000000}>5 bn(50000 lakhs)</MenuItem>
                  <MenuItem value={10000000000}>10 bn(100000 lakhs)</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
          </Menu>
        </>
        {/* Beds and Baths */}
        <>
          <Button
            aria-controls="bnb-menu"
            aria-haspopup="true"
            onClick={handleBNBroomsMenuOpen}
            variant="outlined"
            className="responsive-button"
            endIcon={<ArrowDropDownIcon />}
          >
            {mainRooms ? mainRooms : "Beds\u00A0&\u00A0Baths"}
          </Button>
          <Menu
            id="bnb-menu"
            anchorEl={menuAnchorElBBrooms}
            open={Boolean(menuAnchorElBBrooms)}
            onClose={handleBNBroomsMenuClose}
          >
            <MenuItem>
            <p style={{ textAlign: "center" }}>Bedrooms : </p>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Bedrooms</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={bedRooms}
                  label="Bed Rooms"
                  onChange={handleChange_BedRooms}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem>
            <p style={{ textAlign: "center" }}>BathRooms : </p>
              <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
                <InputLabel id="demo-select-small-label">Bathrooms</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={bathRooms}
                  label="Bath Rooms"
                  onChange={handleChange_BathRooms}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
          </Menu>
        </>
        <>
          <FormControl sx={{ m: 1, width: 150}} size="small">
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            displayEmpty
            value={homeType}
            onChange={handleChange_HomeType}
            input={
              <OutlinedInput
                sx={{
                  fontSize: '14.7px',
                  color: "#0081E7",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#57ACED",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#0081E7",
                  },
                }}
              />
            }
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <>HOME TYPE</>;
              }

              return selected.join(", ");
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Home Type</em>
            </MenuItem>
            {hometypes.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={homeType.indexOf(name) > -1}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </>
        <Button
          variant="contained"
          className="responsive-button"
          onClick={handleSaveSearch}
      >
        save&nbsp;search
      </Button>
      </Stack>
       ) : (
        <>
        <div style={{ marginBottom: '10px', width: '100%', display: 'flex' }}>
        <Search style={{ flexGrow: 1, marginRight: '15px' }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
              placeholder="Colombo, Sri Lanka"
              inputProps={{ "aria-label": "search" }}
              value={value}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              // onBlur={handleBlur}
              endAdornment={
                value && (
                  <ClearButton onClick={handleClearSearch} size="small">
                    <ClearIcon />
                  </ClearButton>
                )}
            />
            {isInputFocused && suggestions.length > 0 && (
              <SuggestionsList>
                {suggestions.map((suggestion) => (
                  <SuggestionItem
                    key={suggestion.place_id}
                    onClick={() => handleSelectSuggestion(suggestion)}
                  >
                    {suggestion.display_name}
                  </SuggestionItem>
                ))}
              </SuggestionsList>
             )}
          </Search>
          </div>
          <div style={{ display: 'flex', gap: '7px', marginLeft: '10px', margin: '0 15px' }}>
          <>
          <Button
          aria-controls="price-menu"
          aria-haspopup="true"
          onClick={handlePriceMenuOpen}
          variant="outlined"
          className="responsive-button"
          endIcon={<ArrowDropDownIcon />}
        >
            Price
          </Button>
          <Menu
            id="price-menu"
            anchorEl={menuAnchorElPrice}
            open={Boolean(menuAnchorElPrice)}
            onClose={handlePriceMenuClose}
          >
            <MenuItem>
            <p style={{ textAlign: "center" }}>Minimum : </p>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Min Price</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={minPrice}
                  label="Min Price"
                  onChange={handleChange_MinPrice}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={1000000}>1 mn(10 lakhs)</MenuItem>
                  <MenuItem value={2000000}>2 mn(20 lakhs)</MenuItem>
                  <MenuItem value={3000000}>3 mn(30 lakhs)</MenuItem>
                  <MenuItem value={4000000}>4 mn(40 lakhs)</MenuItem>
                  <MenuItem value={5000000}>5 mn(50 lakhs)</MenuItem>
                  <MenuItem value={6000000}>6 mn(60 lakhs)</MenuItem>
                  <MenuItem value={7000000}>7 mn(70 lakhs)</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem>
            <p style={{ textAlign: "center" }}>Maximum : </p>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Max Price</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={maxPrice}
                  label="Max Price"
                  onChange={handleChange_MaxPrice}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={7000000}>7 mn(70 lakhs)</MenuItem>
                  <MenuItem value={8000000}>8 mn(80 lakhs)</MenuItem>
                  <MenuItem value={9000000}>9 mn(90 lakhs)</MenuItem>
                  <MenuItem value={10000000}>10 mn(100 lakhs)</MenuItem>
                  <MenuItem value={15000000}>15 mn(150 lakhs)</MenuItem>
                  <MenuItem value={25000000}>25 mn(250 lakhs)</MenuItem>
                  <MenuItem value={50000000}>50 mn(500 lakhs)</MenuItem>
                  <MenuItem value={100000000}>100 mn(1000 lakhs)</MenuItem>
                  <MenuItem value={500000000}>500 mn(5000 lakhs)</MenuItem>
                  <MenuItem value={1000000000}>1 bn(10000 lakhs)</MenuItem>
                  <MenuItem value={5000000000}>5 bn(50000 lakhs)</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
          </Menu>
        </>
        <>
          <Button
            aria-controls="bnb-menu"
            aria-haspopup="true"
            onClick={handleBNBroomsMenuOpen}
            variant="outlined"
            className="responsive-button"
            endIcon={<ArrowDropDownIcon />}
          >
            More
          </Button>
          <Menu
            id="bnb-menu"
            anchorEl={menuAnchorElBBrooms}
            open={Boolean(menuAnchorElBBrooms)}
            onClose={handleBNBroomsMenuClose}
          >
            <MenuItem>
            <p style={{ textAlign: "center" }}>BedRooms   :</p>
              <FormControl sx={{ m: 1, minWidth: 125 }} size="small">
                <InputLabel id="demo-select-small-label">Bedrooms</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={bedRooms}
                  label="Bed Rooms"
                  onChange={handleChange_BedRooms}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem>
            <p style={{ textAlign: "center" }}>Bathrooms :</p>
              <FormControl sx={{ m: 1, minWidth: 125 }} size="small">
                <InputLabel id="demo-select-small-label">Bathrooms</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={bathRooms}
                  label="Bath Rooms"
                  onChange={handleChange_BathRooms}
                >
                  <MenuItem value="">
                    <em>Any</em>
                  </MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </MenuItem>
            <MenuItem>
            <p style={{ textAlign: "center" }}>HomeType :</p>
            <FormControl sx={{ m: 1, width: 130}} size="small">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              value={homeType}
              onChange={handleChange_HomeType}
              input={
                <OutlinedInput
                  sx={{
                    fontSize: '14.7px',
                    color: "#676767"
                  }}
                />
              }
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <>HomeType</>;
                }

                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>Home Type</em>
              </MenuItem>
              {hometypes.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    checked={homeType.indexOf(name) > -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            </MenuItem>
          </Menu>
        </>
        <Button
          variant="contained"
          className="responsive-button"
          onClick={handleSaveSearch}
      >
        save&nbsp;search
      </Button>
          </div>
          </>
       )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfully saved!
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openErrorAlert}
        autoHideDuration={3000}
        onClose={handleErrorAlertClose}>
          <Alert severity="warning">Please SignIn to the system!</Alert>
      </Snackbar>
    </div>
  );
}
