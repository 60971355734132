// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sell__inforsection {
    background-color: #061E63;
    color: white;
    padding-top: 1px;
    padding-left: 70px;
    padding-bottom: 20px;
    font-size: 25px;
  }
  
  /* .sell__inforsection h1 {
    font-size: 40px;
    color: white;
  } */

  .sell_Infoheader{
    font-size: 40px;
    color: white;
    text-align: left;
  }
  
  .sell__headers{
    display: flex;
    font-size: 35px;
    justify-content: center;
  }
  .custom__card {
    background-color: #F1F1F1 !important;
  }
  
  .card__image {
    width: 150px;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .card__text {
    font-size: 20px;
    text-align: center;
  }

  @media (max-width: 900px) {
    .card__text {
      font-size: 15px;
    }
    .card__image {
      width: 100px;
    }
    .custom__card {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 600px){
    .sell__inforsection h1{
      font-size: 25px;
    }
    .sell__inforsection {
      padding-left: 50px;
      font-size: 15px;
    }  
  }`, "",{"version":3,"sources":["webpack://./src/components/realestate/pages/Sell.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;EACjB;;EAEA;;;KAGG;;EAEH;IACE,eAAe;IACf,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;EACzB;EACA;IACE,oCAAoC;EACtC;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE;MACE,eAAe;IACjB;IACA;MACE,YAAY;IACd;IACA;MACE,UAAU;MACV,cAAc;MACd,mBAAmB;IACrB;EACF;EACA;IACE;MACE,eAAe;IACjB;IACA;MACE,kBAAkB;MAClB,eAAe;IACjB;EACF","sourcesContent":[".sell__inforsection {\n    background-color: #061E63;\n    color: white;\n    padding-top: 1px;\n    padding-left: 70px;\n    padding-bottom: 20px;\n    font-size: 25px;\n  }\n  \n  /* .sell__inforsection h1 {\n    font-size: 40px;\n    color: white;\n  } */\n\n  .sell_Infoheader{\n    font-size: 40px;\n    color: white;\n    text-align: left;\n  }\n  \n  .sell__headers{\n    display: flex;\n    font-size: 35px;\n    justify-content: center;\n  }\n  .custom__card {\n    background-color: #F1F1F1 !important;\n  }\n  \n  .card__image {\n    width: 150px;\n    margin-bottom: 10px;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  .card__text {\n    font-size: 20px;\n    text-align: center;\n  }\n\n  @media (max-width: 900px) {\n    .card__text {\n      font-size: 15px;\n    }\n    .card__image {\n      width: 100px;\n    }\n    .custom__card {\n      width: 70%;\n      margin: 0 auto;\n      margin-bottom: 10px;\n    }\n  }\n  @media (max-width: 600px){\n    .sell__inforsection h1{\n      font-size: 25px;\n    }\n    .sell__inforsection {\n      padding-left: 50px;\n      font-size: 15px;\n    }  \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
