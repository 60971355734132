// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    transition: box-shadow 0.5s;
  }
  
  .custom-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .propertyD-card {
    width: 340px;
    height: 340px;
    transition: box-shadow 0.5s;
  }
  
  .propertyD-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  /* @media screen and (max-width: 800px) {
    .propertyD-card {
      width: 100%;
      max-width: 340px;
      height: auto;
      margin-bottom: 10px;
    }
  } */`, "",{"version":3,"sources":["webpack://./src/components/common/MediaCard/StyleCard.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;EAC7B;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;;;;;;;KAOG","sourcesContent":[".custom-card {\n    transition: box-shadow 0.5s;\n  }\n  \n  .custom-card:hover {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);\n  }\n  \n  .propertyD-card {\n    width: 340px;\n    height: 340px;\n    transition: box-shadow 0.5s;\n  }\n  \n  .propertyD-card:hover {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);\n  }\n  \n  /* @media screen and (max-width: 800px) {\n    .propertyD-card {\n      width: 100%;\n      max-width: 340px;\n      height: auto;\n      margin-bottom: 10px;\n    }\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
