import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import Axios from "axios";
import MuiAlert from "@mui/material/Alert";
import jwt from "jwt-decode";
import { TextField, Button,FormControl,Snackbar,FormHelperText } from '@mui/material';
import "./AgentProfile.css";

const API = process.env.REACT_APP_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReviewAndRating = (aId) => {
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
    setLoginError("");
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setLoginError("");

    if(uId){

    const requestBody = {
      value:value,
      comment: comment,
      reviewerName: uId,
      aId,
    };

    // console.log(requestBody);

    Axios.post(`${API}/api/v1/review/`, requestBody)
      .then((response) => {
        if (response) {      
          setOpenAlert(true);

          setLoginError("");
          // console.log("uploaded");
        } else {
          console.error("Invalid response:", response);
          setLoginError("Error occured");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error error:", error.response.data);
        setLoginError("An error occurred. Please try again.");
      });
    }else{
      setLoginError("Before Rating, Please Sign In")
    }
  };

  return (
    <div className="review-box">
      <FormControl className="dataformControl_agent" style={{ marginBottom: "10px" }}>
        <label>Rate the agent *</label>
        <Rating
          name="size-large"
          style={{ marginTop: "10px" }}
          value={value}
          onChange={handleRatingChange}
        />
      </FormControl>
      <FormControl className="dataformControl_agent" style={{ marginBottom: "10px" }}>
        <TextField
          label="Comment"
          multiline
          rows={4}
          variant="outlined"
          value={comment}
          onChange={handleCommentChange}
        />
        </FormControl>
      <div className="submit-button">
        <Button variant="contained" className='btn' onClick={handleSubmit}>Submit</Button>
      </div>
      <div>
        {loginError && (
            <FormHelperText style={{ color: "#D30A0A", fontSize: "14px" }}>{loginError}</FormHelperText>
          )}
        </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfull!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ReviewAndRating;