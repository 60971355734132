// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    width: 85%;
    margin-bottom: 10px;
    margin-left: 35px;
    margin-right: 35px;
    transition: box-shadow 0.5s;
  }
  
  .custom-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .text {
    font-size: 14px;
  }  
  
  .avatar-img {
    width: 120px;
    height: 120px;
    margin-right: 45px;
  }
  .card-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
  @media (max-width: 700px) {
    .avatar-img {
      width: 90px;
      height: 90px;
      margin-right: 15px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/components/common/MediaCard/CardAgent.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;EACE;IACE;MACE,WAAW;MACX,YAAY;MACZ,kBAAkB;IACpB;EACF","sourcesContent":[".custom-card {\n    width: 85%;\n    margin-bottom: 10px;\n    margin-left: 35px;\n    margin-right: 35px;\n    transition: box-shadow 0.5s;\n  }\n  \n  .custom-card:hover {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);\n  }\n  \n  .text {\n    font-size: 14px;\n  }  \n  \n  .avatar-img {\n    width: 120px;\n    height: 120px;\n    margin-right: 45px;\n  }\n  .card-content {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n}\n  @media (max-width: 700px) {\n    .avatar-img {\n      width: 90px;\n      height: 90px;\n      margin-right: 15px;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
