import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
  SuggestionsList,
  SuggestionItem,
} from "./Homestyle";
import PropertyView from "./property/PropertyView";
import NewPropertyView from "./property/NewPropertyView";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import "./Home.css";

export default function Home() {
  const [searchedValue, setSearchedValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleSearch = () => {
    navigate(`/search?address=${searchedValue}`);
  };
  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    setSearchedValue(inputValue);

    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${inputValue}&format=json&countrycodes=LK`
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setSearchedValue(suggestion.display_name);
    setSuggestions([]);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        {isLoading && (
          <Skeleton variant="rectangular" width="100%" height={450} />
        )}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        <img
          className="center"
          src="https://www.villainsrilanka.co.uk/wp-content/uploads/2018/08/Main_Villa17.jpg"
          alt="Sri Lankan Home"
          style={{
            width: "100%",
            height: "450px",
            objectFit: "cover",
            display: isLoading ? "none" : "block",
          }}
          onLoad={handleImageLoaded}
        />
        <div
          style={{
            position: "absolute",
            top: "33%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "#fff",
            fontSize: "20px",
            padding: "10px",
            width: "100%",
          }}
        >
          <h1 className="find-heading" style={{ color: "#fff" }}>
            Find your perfect <br />
            Sri Lankan home
          </h1>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Search>
              <SearchIcon
                onClick={handleSearch}
                style={{
                  padding: "0 10px",
                  height: "85%",
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "red",
                  right: 5,
                  borderRadius: "100%",
                  width: "20px",
                }}
              />
              <StyledInputBase
                placeholder="Colombo, Sri Lanka"
                inputProps={{ "aria-label": "search" }}
                value={searchedValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              {suggestions.length > 0 && (
                <SuggestionsList>
                  {suggestions.map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.place_id}
                      onClick={() => handleSelectSuggestion(suggestion)}
                    >
                      {suggestion.display_name}
                    </SuggestionItem>
                  ))}
                </SuggestionsList>
              )}
            </Search>
          </div>
        </div>
      </div>
      <div>
        <h1 className="home__headers">Explore Sri Lanka Homes</h1>
        <PropertyView />
      </div>
      <div>
        <h1 className="home__headers">Newly listed Homes</h1>
        <NewPropertyView />
      </div>
    </>
  );
}
