// import React, { useRef, useEffect, useState } from 'react';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';

// const PropertyGuestMap = ({ lat, lng }) => {
//     const mapRef = useRef(null);
//     const circleRef = useRef(null);
//     const [googleSatelliteLayer, setGoogleSatelliteLayer] = useState(null);
//     const [esriSatelliteLayer, setEsriSatelliteLayer] = useState(null);

//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           if (lat && lng && mapRef.current) {
//             // Generate a random number between -1 and 1
//             const randomOffset = (Math.random() - 0.5) * 2;

//             // Calculate the delta using the random number
//             const delta = 0.0001 * randomOffset;

//             // Calculate new coordinates
//             const newLat = lat + delta;
//             const newLng = lng + delta;

//             const map = L.map(mapRef.current).setView([newLat, newLng], 14);

//             // Add OpenStreetMap as base layer
//             const osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

//             // Add a circle representing the 5km radius
//             const circle = L.circle([newLat, newLng], {
//               color: 'red',
//               fillColor: 'red',
//               fillOpacity: 0.1,
//               radius: 500, // 500m in meters
//             }).addTo(map);

//             circleRef.current = circle;

//             // Add Google Satellite layer
//             const googleSatelliteLayer = L.tileLayer(
//               'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
//               {
//                 attribution: '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
//                 maxZoom: 24,
//               }
//             );

//             // Add Esri Satellite layer
//             const esriSatelliteLayer = L.tileLayer(
//               'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//               {
//                 attribution: '&copy; Esri',
//                 maxZoom: 18,
//               }
//             );

//             // Group the layers
//             const baseLayers = {
//               'OpenStreetMap': osmLayer,
//             };

//             const overlayLayers = {
//               'Google Satellite View': googleSatelliteLayer,
//               'Esri Satellite View': esriSatelliteLayer,
//             };

//             // Add layers to the map
//             L.control.layers(baseLayers, overlayLayers).addTo(map);

//             setGoogleSatelliteLayer(googleSatelliteLayer);
//             setEsriSatelliteLayer(esriSatelliteLayer);
//           }
//         } catch (error) {
//           console.error(error);
//         }
//       };

//       fetchData();
//     }, [lat, lng]);

//     return (
//       <div>
//         <div id="map" style={{ height: '350px', width: '100%' }} ref={mapRef} />
//       </div>
//     );
//   };

//   export default PropertyGuestMap;

import React, { useRef, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const PropertyGuestMap = ({ lat, lng }) => {
    const mapRef = useRef(null);
    const circleRef = useRef(null);
    const [googleSatelliteLayer, setGoogleSatelliteLayer] = useState(null);
    const [esriSatelliteLayer, setEsriSatelliteLayer] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (lat && lng && mapRef.current) {
            // Generate a random number between -1 and 1
            const randomOffset = (Math.random() - 0.5) * 2;

            // Calculate the delta using the random number
            const delta = 0.0001 * randomOffset;

            // Calculate new coordinates
            const newLat = lat + delta;
            const newLng = lng + delta;

            const map = L.map(mapRef.current).setView([newLat, newLng], 14);

            // Add OpenStreetMap as base layer (initially unchecked)
            const osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');

            // Add Google Satellite layer
            const googleSatelliteLayer = L.tileLayer(
              'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
              {
                attribution: '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
                maxZoom: 21,
              }
            );

            // Add Esri Satellite layer
            const esriSatelliteLayer = L.tileLayer(
              'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              {
                attribution: '&copy; Esri',
                maxZoom: 17,
              }
            );

            // Group the layers
            const baseLayers = {
              'OpenStreetMap': osmLayer,
            };

            const overlayLayers = {
              'Google Satellite View': googleSatelliteLayer,
              'Esri Satellite View': esriSatelliteLayer,
            };

            // Add layers to the map and layer control
            L.control.layers(baseLayers, overlayLayers).addTo(map);
            osmLayer.addTo(map);

            setGoogleSatelliteLayer(googleSatelliteLayer);
            setEsriSatelliteLayer(esriSatelliteLayer);

            // Add a circle representing the 5km radius
            const circle = L.circle([newLat, newLng], {
              color: 'red',
              fillColor: 'red',
              fillOpacity: 0.1,
              radius: 500, // 500m in meters
            }).addTo(map);

            circleRef.current = circle;
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [lat, lng]);

    return (
      <div>
        <div id="map" style={{ height: '350px', width: '100%' }} ref={mapRef} />
      </div>
    );
  };

export default PropertyGuestMap;
