import React, { useState } from 'react';
import './SlideShow.css';

const Slideshow = ({ images, selectedIndex }) => {
  const [slideIndex, setSlideIndex] = useState(selectedIndex+1);

  const plusDivs = (n) => {
    let newIndex = slideIndex + n;

    if (newIndex > images.length) {
      newIndex = 1;
    } else if (newIndex < 1) {
      newIndex = images.length;
    }

    setSlideIndex(newIndex);
  }

  const currentDiv = (n) => {
    setSlideIndex(n);
  }

  return (
    <div className="slideshow-container">
      {images.map((image, index) => (
        <div className={`mySlides fade ${index === slideIndex - 1 ? 'show' : ''}`} key={index}>
          <img src={image} className='image_size' alt={`Slide ${index + 1}`} />
        </div>
      ))}
      <a className="prev" onClick={() => plusDivs(-1)}>&#10094;</a>
      <a className="next" onClick={() => plusDivs(1)}>&#10095;</a>

      <div style={{ textAlign: 'center' }}>
        {images.map((_, index) => (
          <span
            className={`dot ${index === slideIndex - 1 ? 'active' : ''}`}
            key={index}
            onClick={() => currentDiv(index + 1)}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default Slideshow;