import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import Home from "./components/realestate/Home";
import SearchProperty from "./components/realestate/searchProperty/SearchProperty";
import Saved from "./components/realestate/pages/Saved";
import Sell from "./components/realestate/pages/Sell";
import PropertyInfromation from "./components/realestate/property/PropertyInfromation";
import Footer from "./components/common/View/Footer";
import VerificationPage from "./components/user/page/VerificationPage";
import checkUser from "../src/components/user/auth/checkUser";
import VerifyEmail from "./components/user/page/VerifyEmail";
import MyPostings from "./components/realestate/pages/MyPostings";
import PropertyInfoSignIn from "./components/realestate/property/PropertyInfoSignIn";
import ChangePassword from "./components/user/page/ChangePassword";
import Profile from "./components/user/profile/Profile";
import Property from "./components/realestate/sell/Property";
import SavedSearch from "./components/realestate/pages/SavedSearch";
import PropertyListing from "./components/realestate/sell/PropertyListing";
import FindAgent from "./components/user/agent/FindAgent";
import AgentprofileSettings from "./components/user/agent/AgentprofileSettings";
import AgentProfile from "./components/user/agent/AgentProfile";
import UserMenuBar from "./components/common/Navbar/UserMenuBar";
import VerifyStateMenubar from "./components/common/Navbar/VerifyStateMenubar";
import MenuBar from "./components/common/Navbar/MenuBar";
import UpdateProperty from "./components/realestate/property/UpdateProperty";
import TermsandConditions from "./components/user/page/TermsandConditions";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    // Function to fetch the user's details
    const fetchUserDetails = async () => {
      try {
        const userDetails = await checkUser();
        // console.log("userDetails :", userDetails.user);

        if (userDetails) {
          setIsLoggedIn(true);
          setUserInfo(userDetails.user);
        } else {
          setIsLoggedIn(false);
          setUserInfo(null);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setIsLoggedIn(false);
        setUserInfo(null);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <div>
      <Router>
      {isLoggedIn && (
          <Routes>
            <Route
              path="/verifynotice"
              element={<VerifyStateMenubar />}
            />
            <Route
              path="/*"
              element={isLoggedIn ? <UserMenuBar setIsLoggedIn={setIsLoggedIn} userInfo={userInfo} /> : <MenuBar setIsLoggedIn={setIsLoggedIn} />}
            />
          </Routes>
        )}
        {!isLoggedIn && (
          <Routes>
            <Route
              path="/verifynotice"
              element={<VerifyStateMenubar />}
            />
            <Route
              path="/*"
              element={<MenuBar setIsLoggedIn={setIsLoggedIn} />}
            />
          </Routes>
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchProperty />} />
          <Route path="/saved" element={<Saved />} />
          <Route path="/mypostings" element={<MyPostings />} />
          <Route path="/savedsearches" element={<SavedSearch />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/homeinfor/:propertyId" element={<PropertyInfromation />} />
          <Route path="/homeinformation/:propertyId" element={<PropertyInfoSignIn />} />
          <Route path="/verifyEmail/:id" element={<VerifyEmail />} />
          <Route path="/verifynotice" element={<VerificationPage />} />
          <Route path="/changepassword/:email" element={<ChangePassword />} />
          <Route path="/accountsettings/:id" element={<Profile setUserInfo={setUserInfo} setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/agentProfileSettings/:id" element={<AgentprofileSettings setUserInfo={setUserInfo} setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/sell/property" element={<Property userInfo={userInfo} />} />
          <Route path="/findagent/" element={<FindAgent  /> } />
          <Route path="/sell/propertyListing" element={<PropertyListing userInfo={userInfo} />}/>
          <Route path="/agent/:agentId" element={<AgentProfile/>} />
          <Route path="/updateproperty/:propertyId" element={<UpdateProperty/>} />
          <Route path="/termsandconditions" element={<TermsandConditions/>} />
        </Routes>
        <FooterWrapper />
      </Router>
    </div>
  );
}

function FooterWrapper() {
  const location = useLocation();
  const isSearchPage = location.pathname.startsWith("/search");
  return !isSearchPage && (
    <div>
      <Footer />
    </div>
  );
}


export default App;
