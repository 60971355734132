import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Skeleton from '@mui/material/Skeleton';

function AgentLocationMap({ agentLocation }) {
  const [coordinates, setCoordinates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCoordinates = async () => {
      try {
        if (agentLocation.trim() !== "") {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
              agentLocation
            )}`
          );
          const data = await response.json();

          if (data.length > 0) {
            setCoordinates({
              lat: parseFloat(data[0].lat),
              lng: parseFloat(data[0].lon),
            });
          }
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getCoordinates();
  }, [agentLocation]);

  if (isLoading) {
    return <Skeleton variant="rect" width={250} height={100} />;
  }

  if (!coordinates) {
    return null;
  }

  return (
    <MapContainer center={[coordinates.lat, coordinates.lng]} zoom={12} style={{ height: "100px", width: "250px" }} zoomControl={false} dragging={false}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
    </MapContainer>
  );
}

export default AgentLocationMap;