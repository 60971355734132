// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infor__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    max-width: 700px;
    margin: 30px auto 0 !important;
    background-color: #F7F7F7;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  }
  .security__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #F7F7F7;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  }
.formControl {
    width: 100%;
  }
.emailControl {
    width: 80%;
    flex: 1 1;
}
.changePWDControl {
  width: 100%;
}
.error__message {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  color: "#D30A0A";
}
/* h1{
  color: rgb(60, 60, 60);
} */
h4{
    color: rgb(90, 90, 90);
  }
.user__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .form__title {
    color: rgb(60, 60, 60);
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/user/profile/ProfileForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,8BAA8B;IAC9B,yBAAyB;IACzB,6CAA6C;EAC/C;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,6CAA6C;EAC/C;AACF;IACI,WAAW;EACb;AACF;IACI,UAAU;IACV,SAAO;AACX;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;AACA;;GAEG;AACH;IACI,sBAAsB;EACxB;AACF;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,sBAAsB;IACtB,gBAAgB;IAChB,SAAS;IACT,mBAAmB;EACrB","sourcesContent":[".infor__form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 700px;\n    margin: 30px auto 0 !important;\n    background-color: #F7F7F7;\n    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */\n  }\n  .security__form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-radius: 8px;\n    max-width: 700px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #F7F7F7;\n    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */\n  }\n.formControl {\n    width: 100%;\n  }\n.emailControl {\n    width: 80%;\n    flex: 1;\n}\n.changePWDControl {\n  width: 100%;\n}\n.error__message {\n  font-size: 20px;\n  text-align: center;\n  margin-top: 10px;\n  color: \"#D30A0A\";\n}\n/* h1{\n  color: rgb(60, 60, 60);\n} */\nh4{\n    color: rgb(90, 90, 90);\n  }\n.user__info {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n  .form__title {\n    color: rgb(60, 60, 60);\n    text-align: left;\n    margin: 0;\n    margin-bottom: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
