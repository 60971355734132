// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.property-card {
    display: flex;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .property-image {
    width: 100px;
    height: 70px;
    margin-right: 1rem;
    transition: transform 0.2s;
  }
  
  .property-details {
    flex-grow: 1;
  }
  
  .property-card:hover .property-image {
    transform: scale(1.05);
  }
  .property-card:hover .property-details {
    transform: scale(1.02);
  }`, "",{"version":3,"sources":["webpack://./src/components/common/MediaCard/PropertyCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;EAC5B;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB","sourcesContent":[".property-card {\n    display: flex;\n    margin-bottom: 1rem;\n    cursor: pointer;\n  }\n  \n  .property-image {\n    width: 100px;\n    height: 70px;\n    margin-right: 1rem;\n    transition: transform 0.2s;\n  }\n  \n  .property-details {\n    flex-grow: 1;\n  }\n  \n  .property-card:hover .property-image {\n    transform: scale(1.05);\n  }\n  .property-card:hover .property-details {\n    transform: scale(1.02);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
