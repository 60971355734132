import React, { useState, useEffect } from "react";
import Axios from "axios";
import jwt from "jwt-decode";
import { useParams } from "react-router-dom";
import Slideshow from "../gallery/SlideShow";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import PropertyInfoMap from "../map/PropertyInfoMap";
import PropertyGuestMap from "../map/PropertyGuestMap";
import PropertyInfromation from "./PropertyInfromation";
import Person3Icon from "@mui/icons-material/Person3";
import PhoneIcon from "@mui/icons-material/Phone";
import Skeleton from "@mui/material/Skeleton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import MuiAlert from "@mui/material/Alert";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import {
  FormControl,
  TextField,
  Button,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import "./PropertyInformation.css";
import NearByCardSignIn from "../../common/MediaCard/NearByCardSignIn";

const API = process.env.REACT_APP_API;
const NEARBYITEMS_PER_PAGE = 4;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const renderFeatures = (features) => {
//   return Object.entries(features).map(([feature, value]) => {
//     if (feature !== 'parent') {
//       if (typeof value === 'boolean') {
//         // Display only true features
//         return value && <span key={feature} className="feature">{feature}</span>;
//       } else if (typeof value === 'object') {
//         // Recursively render nested features
//         return renderFeatures(value.children);
//       }
//     }
//     return null;
//   });
// };
const renderFeatures = (features) => {
  return Object.entries(features).map(([feature, value]) => {
    if (feature !== "parent") {
      if (typeof value === "boolean") {
        // Display only true features
        return (
          value && (
            <span key={feature} className="feature">
              {feature}
            </span>
          )
        );
      } else if (typeof value === "object") {
        // Check if the feature is "Parking" to handle nested garage options
        if (feature === "Parking" && value.parent) {
          // If the "Parking" feature is true, render the garage options
          return renderGarageOptions(value.children);
        } else {
          // Recursively render other nested features
          return renderFeatures(value);
        }
      }
    }
    return null;
  });
};

// Helper function to render garage options
const renderGarageOptions = (garageOptions) => {
  return Object.entries(garageOptions).map(([garageOption, value]) => {
    if (typeof value === "boolean") {
      return (
        value && (
          <span key={garageOption} className="feature">
            {garageOption}
          </span>
        )
      );
    } else if (typeof value === "object") {
      // Recursively render nested garage options
      return renderGarageOptions(value);
    }
    return null;
  });
};

export default function PropertyInfoSignIn() {
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const { propertyId } = useParams();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [isPropertyFavorite, setIsPropertyFavorite] = useState(null);
  const [clientName, setClientName] = useState("");
  const [useremail, setUseremail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [nearbyProperties, setNearbyProperties] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    Axios.get(`${API}/api/v1/property/details/${propertyId}`)
      .then((response) => {
        setPropertyDetails(response.data.data.property);
        setUserDetails(response.data.data.user);

        CheckfavouriteState(uId, propertyId);
        const latitude = response.data.data.property.location.latitude;
        const longitude = response.data.data.property.location.longitude;
        fetchNearbyProperties(latitude, longitude, propertyId);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [propertyId]);

  const CheckfavouriteState = async (uId, propertyId) => {
    try {
      const response = await Axios.post(
        `${API}/api/v1/property/checkfavourite`,
        { uId, propertyId }
      );
      setIsPropertyFavorite(response.data.isPropertySaved);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNearbyProperties = async (latitude, longitude, propertyId) => {
    try {
      const response = await Axios.post(
        `${API}/api/v1/property/nearby/favstatus`,
        { latitude, longitude, propertyId, uId }
      );
      setNearbyProperties(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  if (!propertyDetails) {
    return (
      <div className="main__box">
        <Skeleton variant="rectangular" width={1300} height={600} />
      </div>
    );
  }

  const currentTime = new Date(); // Get the current time
  const adTime = new Date(propertyDetails.postedAt); // Convert ad's time to a Date object

  // Calculate the time difference in minutes
  const timeDiffInMilliseconds = Math.abs(currentTime - adTime);
  const timeDiffInMinutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));

  // Check if the time difference is more than 60 minutes
  let timeDiffFormatted;
  if (timeDiffInMinutes >= 60) {
    const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
    if (timeDiffInHours >= 24) {
      const timeDiffInDays = Math.floor(timeDiffInHours / 24);
      timeDiffFormatted = `${timeDiffInDays} days`;
    } else {
      timeDiffFormatted = `${timeDiffInHours} hrs`;
    }
  } else {
    timeDiffFormatted = `${timeDiffInMinutes} mins`;
  }

  // Format the price
  function removeTrailingZeros(value) {
    let stringValue = value.toString();

    // Remove trailing zeros after the decimal point
    if (stringValue.includes(".")) {
      stringValue = stringValue.replace(/\.?0*$/, "");
    }

    return stringValue;
  }

  let totalPrice = propertyDetails.price;
  let formattedPrice;

  if (totalPrice >= 1000000) {
    formattedPrice =
      removeTrailingZeros((totalPrice / 1000000).toFixed(2)) + "M";
  } else if (totalPrice >= 100000) {
    formattedPrice =
      removeTrailingZeros((totalPrice / 100000).toFixed(2)) + " Lakhs";
  } else {
    formattedPrice = removeTrailingZeros(totalPrice.toFixed(2));
  }

  // Calculate the total number of pages
  const totalPages = nearbyProperties
    ? Math.ceil(nearbyProperties.count / NEARBYITEMS_PER_PAGE)
    : 0;

  // Get the nearbyProperties for the current page
  const startIndex = (currentPage - 1) * NEARBYITEMS_PER_PAGE;
  const endIndex = startIndex + NEARBYITEMS_PER_PAGE;
  const nearbyPropertiesForCurrentPage = nearbyProperties
    ? nearbyProperties.data.slice(startIndex, endIndex)
    : [];

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleShareClick = () => {
    // Construct the URL to share
    const shareURL = `${window.location.origin}/homeinformation/${propertyId}`;
    const shareText = `Check out this property on SriLankaHome`;

    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: "SriLankaHome",
          text: shareText,
          url: shareURL,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      // Fallback for browsers that do not support the Web Share API
      prompt("Copy this link to share:", shareURL);
    }
  };

  const handleFavoriteClick = (event) => {
    event.stopPropagation();
    Axios.put(`${API}/api/v1/property/favourite/${propertyDetails._id}`, {
      uId,
    })
      .then((res) => {
        // console.log("Favorite status updated successfully");
        setIsPropertyFavorite(!isPropertyFavorite);
      })
      .catch((error) => {
        console.error("Error updating favorite status:", error);
      });
  };

  const openImageSlider = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const closeImageSlider = () => {
    setOpen(false);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const sendMail = () => {
    setSubmitted(true);
    setLoginError("");

    if (
      !isValidName(clientName) ||
      !isValidEmail(useremail) ||
      !isValidMessage(message)
    ) {
      setLoginError("Invalid Input");
      return;
    }

    const requestBody = {
      id: propertyDetails.postedBy,
      clientName: clientName,
      useremail: useremail,
      message: message,
    };

    Axios.post(`${API}/api/v1/user/message`, requestBody)
      .then((response) => {
        if (response) {
          setOpenAlert(true);

          setClientName("");
          setUseremail("");
          setMessage("");
          setLoginError("");
          // console.log("send the email");
        } else {
          console.error("Invalid response:", response);
          setLoginError("Error occured");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        setOpenAlert(false);
        console.error("Error error:", error.response.data);
        setLoginError("An error occurred. Please try again.");
      });
  };

  const isValidName = (clientName) => {
    return typeof clientName === "string" && clientName.trim().length > 0;
  };
  const isValidMessage = (message) => {
    return typeof message === "string" && message.trim() !== "";
  };
  const isValidEmail = (useremail) => {
    return /\S+@\S+\.\S+/.test(useremail);
  };

  return (
    <>
      {uId ? (
        <>
          <div className="main__box">
            {propertyDetails.images.length < 5 ? (
              <ImageList
                className="image_box"
                variant="quilted"
                cols={5}
                rows={2}
              >
                <ImageListItem key={0} cols={3} rows={2}>
                  <img
                    src={propertyDetails.images[0]}
                    alt="Image 0"
                    loading="lazy"
                    className="image_main"
                    onClick={(e) => openImageSlider(0)}
                  />
                  <div className="posted_time">
                    <Typography variant="caption">
                      Posted {timeDiffFormatted} ago
                    </Typography>
                  </div>
                </ImageListItem>
                <ImageListItem key={1} cols={2} rows={1}>
                  <img
                    src={propertyDetails.images[1]}
                    alt="Image 1"
                    loading="lazy"
                    className="image_first"
                    onClick={(e) => openImageSlider(1)}
                  />
                  <div>
                    <IconButton
                      aria-label="Share"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 60,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                      }}
                      onClick={handleShareClick}
                    >
                      <ShareIcon style={{ fontSize: 30, color: "white" }} />
                    </IconButton>
                    <IconButton
                      aria-label="Add to favorites"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 7,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                      }}
                      onClick={handleFavoriteClick}
                    >
                      {isPropertyFavorite ? (
                        <FavoriteIcon
                          style={{
                            fontSize: 30,
                            color: "red",
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{
                            fontSize: 30,
                            color: "white",
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                  <img
                    src={propertyDetails.images[2]}
                    alt="Image 2"
                    loading="lazy"
                    className="image_second"
                    onClick={(e) => openImageSlider(2)}
                  />
                  {propertyDetails.images.length > 3 && (
                    <Avatar
                      className="avatar_design"
                      onClick={(e) => openImageSlider(0)}
                    >
                      +{propertyDetails.images.length - 3}
                    </Avatar>
                  )}
                </ImageListItem>
              </ImageList>
            ) : (
              <ImageList
                className="image_box"
                variant="quilted"
                cols={7}
                rows={2}
              >
                <ImageListItem key={0} cols={3} rows={2}>
                  <img
                    src={propertyDetails.images[0]}
                    alt="Image 0"
                    loading="lazy"
                    className="image_main"
                    onClick={(e) => openImageSlider(0)}
                  />
                  <div className="posted_time">
                    <Typography variant="caption">
                      Posted {timeDiffFormatted} ago
                    </Typography>
                  </div>
                </ImageListItem>
                <ImageListItem key={1} cols={2} rows={1}>
                  <img
                    src={propertyDetails.images[1]}
                    alt="Image 1"
                    loading="lazy"
                    className="image_first"
                    onClick={(e) => openImageSlider(1)}
                  />
                  <img
                    src={propertyDetails.images[2]}
                    alt="Image 2"
                    loading="lazy"
                    className="image_second"
                    onClick={(e) => openImageSlider(2)}
                  />
                </ImageListItem>
                <ImageListItem key={2} cols={2} rows={1}>
                  <img
                    src={propertyDetails.images[3]}
                    alt="Image 3"
                    loading="lazy"
                    className="image_first"
                    onClick={(e) => openImageSlider(3)}
                  />
                  <div>
                    <IconButton
                      aria-label="Share"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 60,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                      }}
                      onClick={handleShareClick}
                    >
                      <ShareIcon style={{ fontSize: 30, color: "white" }} />
                    </IconButton>
                    <IconButton
                      aria-label="Add to favorites"
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 7,
                        backgroundColor: "rgba(0, 0, 0, 0.25)",
                      }}
                      onClick={handleFavoriteClick}
                    >
                      {isPropertyFavorite ? (
                        <FavoriteIcon
                          style={{
                            fontSize: 30,
                            color: "red",
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{
                            fontSize: 30,
                            color: "white",
                          }}
                        />
                      )}
                    </IconButton>
                  </div>
                  <img
                    src={propertyDetails.images[4]}
                    alt="Image 4"
                    loading="lazy"
                    className="image_second"
                    onClick={(e) => openImageSlider(4)}
                  />
                  {propertyDetails.images.length > 5 && (
                    <Avatar
                      className="avatar_design"
                      onClick={(e) => openImageSlider(0)}
                    >
                      +{propertyDetails.images.length - 5}
                    </Avatar>
                  )}
                </ImageListItem>
              </ImageList>
            )}
            <Dialog
              fullScreen
              open={open}
              onClose={closeImageSlider}
              TransitionComponent={Transition}
              PaperProps={{
                style: {
                  backgroundColor: "black",
                },
              }}
            >
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={closeImageSlider}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Toolbar>
              <Slideshow
                images={propertyDetails.images}
                selectedIndex={selectedIndex}
              />
            </Dialog>
          </div>
          <div className="main__box">
            <div className="property_info">
              <Typography gutterBottom className="property_price">
                Rs. {formattedPrice}
              </Typography>
              <Typography className="property_features">
                {propertyDetails.hideAddress
                  ? propertyDetails.address.split(" ").slice(1).join(" ")
                  : propertyDetails.address}
              </Typography>
            </div>
            <div className="property_features_right">
              <div>
                <Typography className="feature_count">
                  {propertyDetails.bedrooms}
                </Typography>
                <Typography className="feature_label">beds</Typography>
              </div>
              <div>
                <Typography className="feature_count">
                  {propertyDetails.bathrooms}
                </Typography>
                <Typography className="feature_label">baths</Typography>
              </div>
              <div>
                <Typography className="feature_count">
                  {propertyDetails.perches}
                </Typography>
                <Typography className="feature_label">perch</Typography>
              </div>
              <div>
                <Typography className="feature_count">
                  {propertyDetails.sqft}
                </Typography>
                <Typography className="feature_label">sqft</Typography>
              </div>
              <div>
                <div className="status_circle">
                  {propertyDetails.isActive ? (
                    <div className="active-circle">
                      <DoneOutlinedIcon className="icon" />
                    </div>
                  ) : (
                    <div className="inactive-circle">
                      <ClearOutlinedIcon className="icon" />
                    </div>
                  )}
                </div>
                <Typography className="active_label">
                  {propertyDetails.isActive ? "Active" : `Off\u00A0Market`}
                </Typography>
              </div>
            </div>
          </div>
          <div className="main__box">
            <Typography variant="body1" gutterBottom>
              <h1 style={{ textAlign: "left" }}>Description</h1>
              <div style={{ whiteSpace: "pre-line" }}>
                {propertyDetails.description.trim()}
              </div>
            </Typography>
          </div>
          <div className="main__box">
            <Typography variant="body1" gutterBottom>
              <h1 style={{ textAlign: "left" }}>Property Features</h1>
              <div className="feature-list">
                {propertyDetails.features &&
                  renderFeatures(propertyDetails.features)}
              </div>
            </Typography>
          </div>

          <div className="map_box">
            <h1 style={{ textAlign: "left" }}>Location</h1>
            {propertyDetails.hideAddress ? (
              <PropertyGuestMap
                lat={propertyDetails.location.latitude}
                lng={propertyDetails.location.longitude}
              />
            ) : (
              <PropertyInfoMap
                lat={propertyDetails.location.latitude}
                lng={propertyDetails.location.longitude}
              />
            )}
          </div>
          <div className="main__box">
            <div className="contact_details">
              <Typography variant="body1" gutterBottom>
                <h1 style={{ textAlign: "left" }}>Contact Advertiser</h1>
              </Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Person3Icon className="user_icon" />
                <Typography className="name_label">
                  {" "}
                  : {userDetails.firstName} {userDetails.lastName}
                </Typography>
                <PhoneIcon className="user_icon" />
                <Typography className="name_label">
                  {" "}
                  : {userDetails.mobileNumber}
                </Typography>
              </div>
              <FormControl
                className="data_formControl"
                style={{ marginTop: "5px", marginBottom: "10px" }}
              >
                <TextField
                  id="outlined-basic-name"
                  label="Your Name *"
                  variant="outlined"
                  placeholder="Enter your name"
                  value={clientName}
                  onChange={(event) => {
                    setClientName(event.target.value);
                    setLoginError("");
                  }}
                />
              </FormControl>

              <FormControl
                className="data_formControl"
                style={{ marginBottom: "10px" }}
              >
                <TextField
                  id="outlined-basic-email"
                  label="Your Email *"
                  variant="outlined"
                  placeholder="Enter your email"
                  value={useremail}
                  onChange={(event) => {
                    setUseremail(event.target.value);
                    setLoginError("");
                  }}
                />
              </FormControl>
              <FormControl
                className="data_formControl"
                style={{ marginBottom: "10px" }}
              >
                <TextField
                  id="outlined-multiline-static-mg"
                  label="Message *"
                  variant="outlined"
                  placeholder="Enter the message"
                  multiline
                  rows={4}
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                    setLoginError("");
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                sx={{ width: "100%", height: "40px" }}
                onClick={sendMail}
              >
                Send
              </Button>
              <div>
                {loginError && (
                  <FormHelperText
                    style={{ color: "#D30A0A", fontSize: "14px" }}
                  >
                    {loginError}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div className="nearby_home">
              <Typography variant="body1" gutterBottom>
                <h1 style={{ textAlign: "left" }}>Nearby Homes</h1>
              </Typography>
              <div>
                {nearbyPropertiesForCurrentPage === null ? (
                  <div>
                    {Array.from(new Array(NEARBYITEMS_PER_PAGE)).map(
                      (_, index) => (
                        <div key={index} className="property_cards_container">
                          <Skeleton
                            variant="rectangular"
                            width={240}
                            height={250}
                          />
                        </div>
                      )
                    )}
                  </div>
                ) : nearbyPropertiesForCurrentPage.length === 0 ? (
                  <div>
                    <h4>
                      No Nearby properties found for sale in the specified area
                    </h4>
                  </div>
                ) : (
                  <div className="property_cards_container">
                    {nearbyPropertiesForCurrentPage.map((property) => (
                      <div key={property._id.toString()}>
                        <NearByCardSignIn
                          propertyDetails={property}
                          uId={uId}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <br />
              {totalPages > 1 && (
                <div className="pagination_container">
                  <Pagination
                    className="custom_pagination"
                    count={totalPages}
                    page={currentPage}
                    variant="outlined"
                    onChange={handlePageChange}
                    size="large"
                  />
                </div>
              )}
            </div>
          </div>
          {/* <div className='main__box'>
      <Typography variant="body1" gutterBottom>
         {propertyDetails.reachId !== -1 && (
        <div>
        <h1>Fluvisat Data</h1>
            Explore detailed fluid information near the property by visiting{" "}
            <a
              href={`https://riverreaches-app-dac1d9e843c0.herokuapp.com/moreinfor/${propertyDetails.reachId}?propertyId=${propertyDetails._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fluvisat.com
            </a>
          </div>
        )}
      </Typography>
  </div> */}
          <div className="main__box">
            <Typography variant="body1" gutterBottom>
              <div>
                <h1 style={{ textAlign: "left" }}>Products</h1>
                Explore the products and solutions Citro has to offer
              </div>
            </Typography>
          </div>
          <div className="product__box">
            {propertyDetails.reachId !== -1 ? (
              <Link
                to={`https://riverreaches-app-dac1d9e843c0.herokuapp.com/moreinfor/${propertyDetails.reachId}?propertyId=${propertyDetails._id}`}
                style={{ textDecoration: "none" }}
              >
                <Card className="product-card">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="180"
                      src={require("../../assets/Flood Indundation.jpeg")}
                      alt="fluvisat.com"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Check risk for flood
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Explore detailed fluid information near the property
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            ) : (
              <Card className="product-card">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="180"
                    image="https://upload.wikimedia.org/wikipedia/commons/e/e0/Kinshasa_%26_Brazzaville_-_ISS007-E-6305_lrg.jpg"
                    alt="fluvisat.com"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Check risk for flood
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Explore detailed fluid information near the property
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            )}
            <Card className="product-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  style={{ height: 180, width: "100%", objectFit: "contain" }}
                  // image="https://www.theschoolrun.com/sites/theschoolrun.com/files/weather_.jpg"
                  src={require("../../assets/weather_derivatives.png")}
                  alt="Weather data"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Weather Derivatives
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Explore financial weather derivatives for precise risk
                    management in changing climates
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card className="product-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  // image="https://www.shutterstock.com/image-photo/photo-collage-solar-panels-photovoltaics-600nw-2131413323.jpg"
                  image="https://dlczb9lfz9r73.cloudfront.net/wp-content/uploads/2022/10/29202808/Solar-AdobeStock_108592983-scaled-e1694856204242.jpeg.webp"
                  alt="Solar Power"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Energy cost calculation
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Harness the sun's energy with clean and sustainable solar
                    power solutions for a brighter, eco-friendly tomorrow
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div className="product__box">
            <Card className="product-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image="https://gpm.nasa.gov/landslides/images/LHASA%20model%202018.jpg"
                  alt="Land Slides"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Land Slides detector
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Embark on a journey through historical landslide data,
                    delving into the Earth's intricate story on our platform
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card className="product-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image="https://radar.weather.gov/ridge/standard/KAKQ_loop.gif"
                  alt="Weather data"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Weather for this home
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Discover real time weather information for precise forecasts
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card className="product-card">
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="180"
                  image="https://images.nationalgeographic.org/image/upload/t_edhub_resource_key_image/v1638891825/EducationHub/photos/black-saturday-bushfires.jpg"
                  alt="Wildfires/Bushfires"
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Wildfires/Bushfires
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Navigating the wildfire landscape with real-time updates and
                    expert insights
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openAlert}
            autoHideDuration={5000}
            onClose={handleAlertClose}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Successfully sent the message!
            </Alert>
          </Snackbar>
        </>
      ) : (
        <PropertyInfromation />
      )}
    </>
  );
}
