import React, { useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import "./LocationSelector.css";

const LocationSelector = ({ onSelect, address, selectedPosition }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [manualLat, setManualLat] = useState('');
  const [manualLng, setManualLng] = useState('');

  useEffect(() => {
    let newMap = null;
    let newMarker = null;

    const fetchData = async () => {
      try {
        if (address) {
          let initialLat, initialLng;

          if (selectedPosition.lat && selectedPosition.lng) {
            initialLat = selectedPosition.lat;
            initialLng = selectedPosition.lng;
          } else {
            const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${encodeURIComponent(address)}`);
            const data = response.data[0];

            if (data && data.lat && data.lon) {
              initialLat = data.lat;
              initialLng = data.lon;
            }
          }

          if (!map) {
            newMap = L.map('map').setView([initialLat, initialLng], 18);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(newMap);
            setMap(newMap);
          } else {
            newMap = map;
          }
          
          if (!marker) {
            newMarker = L.circleMarker([initialLat, initialLng], {
              color: 'red',
              fillColor: 'red',
              fillOpacity: 1,
              radius: 6
            }).addTo(newMap);
            setMarker(newMarker);
          } else {
            newMarker = marker;
          }          

          newMap.on('click', (e) => {
            const { lat, lng } = e.latlng;
            onSelect(lat, lng);
            newMarker.setLatLng([lat, lng]);
            setManualLat(lat.toString());
            setManualLng(lng.toString());
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

  }, [onSelect, address, map, marker, selectedPosition]);

  useEffect(() => {
    if (selectedPosition.lat !== null && selectedPosition.lng !== null) {
      setManualLat(selectedPosition.lat);
      setManualLng(selectedPosition.lng);
    }
  }, [selectedPosition]);

  const navigateToLocation = () => {
    const lat = parseFloat(manualLat);
    const lon = parseFloat(manualLng);

    if (!isNaN(lat) && !isNaN(lon) && map) {
      map.setView([lat, lon], 18);

      if (marker) {
        marker.setLatLng([lat, lon]);
      }

      onSelect(lat, lon);
    }
  };

  useEffect(() => {
    if (map) {
      const baseLayers = {
        'OpenStreetMap': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map)
      };

      const overlayLayers = {
        'Esri Satellite Overlay': L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: '&copy; Esri',
            maxZoom: 17,
            opacity: 1
          }
        ),
        'Google Satellite Overlay': L.tileLayer(
          'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
          {
            attribution: '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
            maxZoom: 21,
            opacity: 1
          }
        )
      };

      L.control.layers(baseLayers, overlayLayers, { position: 'topright' }).addTo(map);
    }
  }, [map]);

  return (
    <div style={{ position: 'relative', height: '450px', width: '100%' }}>
    {isLoading && (
      <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Skeleton variant="rectangular" width="100%" height={450} />
      </div>
    )}
    <div id="map" style={{ height: '100%', width: '100%' }} />
    <div style={{ marginTop: '2px',display: 'flex', gap: '10px' }}>
      <input
        type="number"
        placeholder="Latitude"
        id="latInput"
        value={manualLat}
        onChange={(e) => setManualLat(e.target.value)}
        style={{ height: '40px' }}
      />
      <input
        type="number"
        placeholder="Longitude"
        id="lonInput"
        value={manualLng}
        onChange={(e) => setManualLng(e.target.value)}
        style={{ height: '40px' }}
      />
      <Button sx={{height: '40px'}} size='medium' variant="outlined" onClick={navigateToLocation}>
        Enter
      </Button>
    </div>
  </div>
  );
};

export default LocationSelector;

// import React, { useState, useEffect } from 'react';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import axios from 'axios';
// import Skeleton from '@mui/material/Skeleton';
// import Button from '@mui/material/Button';
// import Switch from '@mui/material/Switch';
// import "./LocationSelector.css";

// const LocationSelector = ({ onSelect, address }) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [esriLayer, setEsriLayer] = useState(null);
//   const [map, setMap] = useState(null);
//   const [marker, setMarker] = useState(null);
//   const [manualLat, setManualLat] = useState('');
//   const [manualLng, setManualLng] = useState('');

//   useEffect(() => {
//     let newMap = null;
//     let newMarker = null;

//     const fetchData = async () => {
//       try {
//         if (address) {
//           const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&limit=1&q=${encodeURIComponent(address)}`);
//           const data = response.data[0];
          
//           if (data && data.lat && data.lon) {
//             const { lat, lon } = data;

//             if (!map) {
//               newMap = L.map('map').setView([lat, lon], 18);
//               L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(newMap);
//               setMap(newMap);
//             }

//             if (!marker) {
//               newMarker = L.circleMarker([lat, lon], {
//                 color: 'red',
//                 fillColor: 'red',
//                 fillOpacity: 1,
//                 radius: 6
//               }).addTo(newMap);
//               setMarker(newMarker);
//             }

//             newMap.on('click', (e) => {
//               const { lat, lng } = e.latlng;
//               onSelect(lat, lng);
//               newMarker.setLatLng([lat, lng]);
//               setManualLat(lat);
//               setManualLng(lng);
//             });

//             const esriSatelliteLayer = L.tileLayer(
//               'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
//               {
//                 attribution: '&copy; Esri',
//                 maxZoom: 18,
//                 opacity: 0,
//               }
//             ).addTo(newMap);

//             setEsriLayer(esriSatelliteLayer);
//           }
//         }
//       } catch (error) {
//         console.error(error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();

//     return () => {
//       // Clean up map and marker when component unmounts
//       if (newMap) {
//         newMap.remove();
//       }
//       if (newMarker) {
//         newMarker.remove();
//       }
//     };
//   }, [onSelect, address, map, marker]);

//   const toggleEsriLayer = () => {
//     if (esriLayer) {
//       esriLayer.setOpacity(esriLayer.options.opacity === 1 ? 0 : 1);
//     }
//   };

//   const navigateToLocation = () => {
//     const lat = parseFloat(manualLat);
//     const lon = parseFloat(manualLng);

//     if (!isNaN(lat) && !isNaN(lon) && map) {
//       map.setView([lat, lon], 18);

//       if (marker) {
//         marker.setLatLng([lat, lon]);
//       }

//       onSelect(lat, lon);
//     }
//   };

//   return (
//     <div style={{ position: 'relative', height: '450px', width: '100%' }}>
//       {isLoading && (
//         <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//           <Skeleton variant="rectangular" width="100%" height={450} />
//         </div>
//       )}
//       <div id="map" style={{ height: '100%', width: '100%' }} />
//       <div style={{ marginTop: '2px',display: 'flex', gap: '10px' }}>
//         <input
//           type="number"
//           placeholder="Latitude"
//           id="latInput"
//           value={manualLat}
//           onChange={(e) => setManualLat(e.target.value)}
//         />
//         <input
//           type="number"
//           placeholder="Longitude"
//           id="lonInput"
//           value={manualLng}
//           onChange={(e) => setManualLng(e.target.value)}
//         />
//         <Button sx={{height: '40px'}} size='medium' variant="outlined" onClick={navigateToLocation}>
//           Enter
//         </Button>
//         <Button size='medium' variant="text" onClick={toggleEsriLayer} startIcon={<Switch />} />
//         <p className='pargraph'>Satellite View</p>
//       </div>
//     </div>
//   );
// };

// export default LocationSelector;