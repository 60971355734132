// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .terms-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .terms-content {
    padding: 20px;
  }
  
  h1 {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  strong {
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/user/page/TermsandConditions.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["  .terms-container {\n    padding: 20px;\n    max-width: 1000px;\n    margin: 0 auto;\n    font-family: Arial, sans-serif;\n    line-height: 1.6;\n    color: #333;\n  }\n  \n  .terms-content {\n    padding: 20px;\n  }\n  \n  h1 {\n    font-size: 2em;\n    color: #333;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  p {\n    margin-bottom: 20px;\n  }\n  \n  a {\n    color: #007bff;\n    text-decoration: none;\n  }\n  \n  a:hover {\n    text-decoration: underline;\n  }\n  \n  strong {\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
