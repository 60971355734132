// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-button {
    font-size: 10px;
    padding: 8px 16px;
}


@media (max-width: 600px) {
  .responsive-button {
    font-size: 7px;
    padding: 6px 12px !important;
  }
}
@media (max-width: 800px) {
    .responsive-buttons-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/realestate/advancedSearch/AdvancedSearch.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;AACrB;;;AAGA;EACE;IACE,cAAc;IACd,4BAA4B;EAC9B;AACF;AACA;IACI;MACE,aAAa;MACb,eAAe;MACf,8BAA8B;IAChC;EACF","sourcesContent":[".responsive-button {\n    font-size: 10px;\n    padding: 8px 16px;\n}\n\n\n@media (max-width: 600px) {\n  .responsive-button {\n    font-size: 7px;\n    padding: 6px 12px !important;\n  }\n}\n@media (max-width: 800px) {\n    .responsive-buttons-container {\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: space-between;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
