import React, { useEffect, useState } from "react";
import Axios from 'axios';
import jwt from "jwt-decode";
import NewPropertyCard from "../../common/MediaCard/NewPropertyCard";
import NewCardSignIn from "../../common/MediaCard/NewCardSignIn";
import Skeleton from '@mui/material/Skeleton';
import "./PropertyView.css";

const API = process.env.REACT_APP_API;

export default function NewPropertyView() {
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savedProperties, setSavedProperties] = useState([]);
  const token = localStorage.getItem("token");
  const uId = token ? jwt(token).id : null;

  const refreshPage = () => {
    Axios.get(`${API}/api/v1/property/new`)
      .then((res) => {
        setProperty(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const fetchSavedProperties = () => {
    Axios.get(`${API}/api/v1/user/savedproperty/${uId}`)
      .then((res) => {
        setSavedProperties(res.data.data);
        refreshPage();
      })
      .catch((error) => {
        console.error("Error fetching saved properties:", error);
      });
  };

  useEffect(() => {
    if (uId == null) {
      refreshPage();
    } else {
      // console.log("NEW prop: USER Logged")
      // refreshPage();
      fetchSavedProperties();
    }
  }, []);
  

  return (
    <div className="Estate_component">
      {loading ? (
        Array.from(new Array(4)).map((_, index) => (
          <div key={index} className="Card_wrapper">
            <Skeleton variant="rectangular" width={330} height={230} />
            <Skeleton variant="text" width={200} height={20} />
            <Skeleton variant="text" width={150} height={20} />
          </div>
        ))
      ) : (
        property.map((p) => (
          <div key={p._id.toString()} className="Card_wrapper">
            {uId ? (
              <NewCardSignIn propertyDetails={p} refreshPage={refreshPage} uId={uId} savedProperties={savedProperties} />
            ) : <NewPropertyCard propertyDetails={p} refreshPage={refreshPage} />
            }           
          </div>
        ))
      )}
    </div>
  );
}
