// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#latInput,
#lonInput {
  width: 20%;
  padding: 6px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#latInput:focus,
#lonInput:focus {
  border-color: #007bff;
  outline: none;
}
.pargraph {
  display: block;
}
@media screen and (max-width: 600px) {
  
  #latInput,
  #lonInput {
  width: 100%;
  height: 40px;
  padding: 6px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.pargraph {
  display: none;
}
}`, "",{"version":3,"sources":["webpack://./src/components/realestate/map/LocationSelector.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;;EAEE,qBAAqB;EACrB,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;;EAEE;;EAEA,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;AACA","sourcesContent":["#latInput,\n#lonInput {\n  width: 20%;\n  padding: 6px;\n  margin-bottom: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n\n#latInput:focus,\n#lonInput:focus {\n  border-color: #007bff;\n  outline: none;\n}\n.pargraph {\n  display: block;\n}\n@media screen and (max-width: 600px) {\n  \n  #latInput,\n  #lonInput {\n  width: 100%;\n  height: 40px;\n  padding: 6px;\n  margin-bottom: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-sizing: border-box;\n}\n.pargraph {\n  display: none;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
