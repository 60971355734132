// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.square-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
  
.square {
    background-color: red;
    border-radius: 50%;
    width: 40px;
    height: 10px;
    border: 1px solid blue !important;
}
.square-icon :hover {
    background-color: #2196f3;
}

.circle-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    background-color: red;
    width: 50px;
    height: 50px;
    border: 20px solid black !important;
}

.circle-icon:hover {
    background-color: #2196f3;
}
`, "",{"version":3,"sources":["webpack://./src/components/realestate/map/OSM.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iCAAiC;AACrC;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,mCAAmC;AACvC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".square-icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n}\n  \n.square {\n    background-color: red;\n    border-radius: 50%;\n    width: 40px;\n    height: 10px;\n    border: 1px solid blue !important;\n}\n.square-icon :hover {\n    background-color: #2196f3;\n}\n\n.circle-icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    border-radius: 50%;\n    background-color: red;\n    width: 50px;\n    height: 50px;\n    border: 20px solid black !important;\n}\n\n.circle-icon:hover {\n    background-color: #2196f3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
