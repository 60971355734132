// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card__link {
    text-decoration: none !important;
    color: #333;
    display: block;
    text-align: center;
    transition: box-shadow 0.5s;
    border-radius: 20px;
  }
  
  .card__link:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
  
  .upload__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    background-color: #f5f5f5;
  }
  
  .uploadnotice__content {
    font-size: 18px;
    text-align: center;
    max-width: 500px;
    margin: 0 auto 20px;
  }
  @media (max-width: 900px) {
    .card__link:hover {
      box-shadow: none;
      border-radius: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/realestate/sell/Property.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,0CAA0C;IAC1C,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE;MACE,gBAAgB;MAChB,gBAAgB;IAClB;EACF","sourcesContent":[".card__link {\n    text-decoration: none !important;\n    color: #333;\n    display: block;\n    text-align: center;\n    transition: box-shadow 0.5s;\n    border-radius: 20px;\n  }\n  \n  .card__link:hover {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);\n    border-radius: 20px;\n  }\n  \n  .upload__page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 70vh;\n    background-color: #f5f5f5;\n  }\n  \n  .uploadnotice__content {\n    font-size: 18px;\n    text-align: center;\n    max-width: 500px;\n    margin: 0 auto 20px;\n  }\n  @media (max-width: 900px) {\n    .card__link:hover {\n      box-shadow: none;\n      border-radius: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
