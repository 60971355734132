import React, { useState } from "react";
import Axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase } from "./AgentSearch";
import { Skeleton } from "@mui/material";
import CardAgent from "../../common/MediaCard/CardAgent";

const API = process.env.REACT_APP_API;

export default function FindAgent() {
  const [location, setSearchedLocation] = useState("");
  const [agentName, setAgentName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setSearchPerformed(true);
    setLoading(true);
    Axios.get(`${API}/api/v1/user/searchagent/`, {
      params: { location, agentName },
    })
      .then((response) => {
        setSearchResults(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <h1
        style={{
          marginLeft: "50px",
          fontSize: "30px",
          marginTop: "20px",
          textAlign: "left",
        }}
      >
        Find an Agent
      </h1>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <div style={{ flex: "1 1 50%", minWidth: "300px" }}>
          <h3 style={{ marginLeft: "50px" }}>Location</h3>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="City"
              inputProps={{ "aria-label": "search" }}
              value={location}
              onChange={(e) => setSearchedLocation(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </Search>
        </div>

        <div style={{ flex: "1 1 50%", minWidth: "300px" }}>
          <h3 style={{ marginLeft: "50px" }}>Name</h3>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Agent Name"
              inputProps={{ "aria-label": "search" }}
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </Search>
        </div>
      </div>
      <br />

      {/* Conditionally render the initial content based on searchPerformed */}
      {!searchPerformed && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "50px",
            flexWrap: "wrap",
          }}
        >
          <img
            src="https://media.istockphoto.com/id/184932880/photo/detective.jpg?s=612x612&w=0&k=20&c=9A3-8p3MxOp7COxw2bq0KpMSBlSOnjgSiFz69Y7NwkI="
            height="200"
            width="250"
            style={{ marginRight: "10px" }}
          />
          <div>
            <h3>Locate agents in your area.</h3>
            <h4>
              Start by entering the location or searching for an agent by name.
            </h4>
          </div>
        </div>
      )}

      <div>
        {loading ? (
          Array.from(new Array(4)).map((_, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <Skeleton
                variant="rectangular"
                width="85%"
                height={110}
                style={{ marginLeft: "50px" }}
              />
            </div>
          ))
        ) : (
          <div>
            {searchPerformed ? (
              searchResults.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40vh",
                  }}
                >
                  <h2>Not found Any Results</h2>
                </div>
              ) : (
                searchResults.map((agent) => (
                  <CardAgent key={agent._id} agent={agent} />
                ))
              )
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}
