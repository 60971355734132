import React, { useState } from "react";
import Axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Checkbox from "@mui/material/Checkbox";

const API = process.env.REACT_APP_API;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MyPropertyCard({
  propertyDetails,
  refreshPage,
  uId,
  savedProperties,
}) {
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [isPropertyFavorite, setIsPropertyFavorite] = useState(
    savedProperties.includes(propertyDetails._id)
  );
  const [isActive, setIsActive] = useState(propertyDetails.isActive);
  const [isPropertySold, setIsPropertySold] = useState(propertyDetails.isSold);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleCardClick = () => {
    navigate(`/homeinformation/${propertyDetails._id}`, {
      state: {
        property: propertyDetails,
        favourite: isPropertyFavorite,
        uId: uId,
      },
    });
  };

  const updateProperty = (e) => {
    e.stopPropagation();
    navigate(`/updateproperty/${propertyDetails._id}`, {
      state: {
        property: propertyDetails,
      },
    });
  };

  const handleToggle = async (e) => {
    e.stopPropagation();
    try {
      const newIsActive = !isActive;
      setIsActive(newIsActive);

      await Axios.put(`${API}/api/v1/property/${propertyDetails._id}`, {
        isActive: newIsActive,
      });
      // console.log("successful")
    } catch (error) {
      console.error("Error toggling property status:", error);
    }
  };

  const handleSoldToggle = async (e) => {
    e.stopPropagation();
    const newIsSold = !isPropertySold;
    setIsPropertySold(newIsSold);

    try {
      // Make the Axios request to update the sold status
      await Axios.put(`${API}/api/v1/property/${propertyDetails._id}`, {
        isSold: newIsSold,
        // Add the isActive property based on isSold value
        isActive: newIsSold ? false : isActive,
      });
      // console.log("Property sold status updated successfully");

      // If isSold is true, also update isActive to false in the state
      if (newIsSold) {
        setIsActive(false);
      }
    } catch (error) {
      console.error("Error toggling property sold status:", error);
    }
  };

  // const handleDeleteConfirmed = (e) => {
  //   e.stopPropagation();
  //   Axios.delete(`${API}/api/v1/property/${propertyDetails._id}`)
  //     .then((res) => {
  //       setOpenAlert(true);
  //       console.log("delete successfully");
  //       refreshPage();
  //     })
  //     .catch((error) => {
  //       setOpenAlert(false);
  //       console.error("Error occured when deleting:", error);
  //     });
  //     setIsDeleteModalOpen(false);
  // };

  const handleFavoriteClick = (event) => {
    event.stopPropagation();
    Axios.put(`${API}/api/v1/property/favourite/${propertyDetails._id}`, {
      uId,
    })
      .then((res) => {
        // console.log("Favorite status updated successfully");
        setIsPropertyFavorite(!isPropertyFavorite);
        refreshPage();
      })
      .catch((error) => {
        console.error("Error updating favorite status:", error);
      });
  };

  // Calculate the time difference in minutes
  const currentTime = new Date();
  const adTime = new Date(propertyDetails.postedAt);
  const timeDiffInMilliseconds = Math.abs(currentTime - adTime);
  const timeDiffInMinutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));

  // Check if the time difference is more than 60 minutes
  let timeDiffFormatted;
  if (timeDiffInMinutes === 0) {
    timeDiffFormatted = "Just now";
  } else if (timeDiffInMinutes >= 60) {
    const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
    if (timeDiffInHours >= 24) {
      const timeDiffInDays = Math.floor(timeDiffInHours / 24);
      timeDiffFormatted = `${timeDiffInDays} days ago`;
    } else {
      timeDiffFormatted = `${timeDiffInHours} hrs ago`;
    }
  } else {
    timeDiffFormatted = `${timeDiffInMinutes} mins ago`;
  }

  function removeTrailingZeros(value) {
    let stringValue = value.toString();

    // Remove trailing zeros after the decimal point
    if (stringValue.includes(".")) {
      stringValue = stringValue.replace(/\.?0*$/, "");
    }

    return stringValue;
  }

  let totalPrice = propertyDetails.price;
  let formattedPrice;

  if (totalPrice >= 1000000) {
    formattedPrice =
      removeTrailingZeros((totalPrice / 1000000).toFixed(2)) + "M";
  } else if (totalPrice >= 100000) {
    formattedPrice =
      removeTrailingZeros((totalPrice / 100000).toFixed(2)) + " Lakhs";
  } else {
    formattedPrice = removeTrailingZeros(totalPrice.toFixed(2));
  }

  return (
    <>
      <Card
        sx={{ width: 350, height: 390, cursor: "pointer" }}
        onClick={handleCardClick}
      >
        <div style={{ position: "relative" }}>
          <CardMedia
            component="img"
            height="215"
            image={propertyDetails.images[0]}
          />
          <div
            style={{
              position: "absolute",
              top: 5,
              left: 5,
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              color: "white",
              borderRadius: "8px",
              padding: "4px 8px",
            }}
          >
            <Typography variant="caption">
              Posted {timeDiffFormatted}
            </Typography>
          </div>
          <IconButton
            aria-label="Add to favorites"
            style={{
              position: "absolute",
              top: 5,
              right: 7,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
            onClick={handleFavoriteClick}
          >
            {isPropertyFavorite ? (
              <FavoriteIcon
                style={{
                  fontSize: 30,
                  color: "red",
                }}
              />
            ) : (
              <FavoriteBorderIcon
                style={{
                  fontSize: 30,
                  color: "white",
                }}
              />
            )}
          </IconButton>
        </div>

        <CardContent>
          <Typography gutterBottom sx={{ fontSize: "18px", fontWeight: 550 }}>
            Rs. {formattedPrice}
          </Typography>
          <Typography
            style={{
              fontSize: "15px",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <span>{propertyDetails.bedrooms} bds</span>
            <span>|</span>
            <span>{propertyDetails.bathrooms} bth</span>
            <span>|</span>
            <span>{propertyDetails.perches} perch</span>
            <span>|</span>
            <span>{propertyDetails.sqft} sqft</span>
          </Typography>

          <Typography
            gutterBottom
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "15px",
            }}
          >
            <span>
              {propertyDetails.hideAddress
                ? propertyDetails.address.split(" ").slice(1).join(" ")
                : propertyDetails.address}
            </span>
          </Typography>
          <Typography>
            <Button
              color="success"
              variant="outlined"
              onClick={updateProperty}
              style={{ marginRight: "8px" }}
            >
              Edit
            </Button>
            <ToggleButtonGroup
              value={isActive ? "active" : "inactive"}
              exclusive
            >
              <ToggleButton
                value="active"
                onClick={handleToggle}
                style={{
                  backgroundColor: isActive ? "#2196f3" : "white",
                  color: isActive ? "white" : "#2196f3",
                }}
                size="small"
              >
                Active
              </ToggleButton>
              <ToggleButton
                value="inactive"
                onClick={handleToggle}
                style={{
                  backgroundColor: isActive ? "white" : "#2196f3",
                  color: isActive ? "#2196f3" : "white",
                }}
                size="small"
              >
                Off Market
              </ToggleButton>
            </ToggleButtonGroup>
            <Checkbox
              checked={isPropertySold}
              onChange={handleSoldToggle}
              sx={{
                color: "#ffeb3b",
                "&.Mui-checked": {
                  color: "#fbc02d",
                },
              }}
              onClick={(e) => e.stopPropagation()}
              inputProps={{ "aria-label": "property-sold-checkbox" }}
            />
            <span style={{ marginLeft: "1px", fontSize: "17px" }}>Sold</span>
          </Typography>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfully Deactivated!
        </Alert>
      </Snackbar>
    </>
  );
}
