import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteProperty from "../../realestate/property/DeleteProperty";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import "./StyleCard.css";

const API = process.env.REACT_APP_API;
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const cardStyle = {
  marginLeft: 1,
  width: '100%',
  minWidth: '300px',
  marginBottom: 5,
  position: "relative",
};

const deleteIconStyle = {
  position: "absolute",
  top: "50%",
  right: 5,
  transform: "translateY(-50%)",
  color: "red",
};

export default function SearchCard({ searchDetails, uId, refreshPage }) {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

const handleNavigateToBuy = () => {
  const address = searchDetails.address || '';
  const minPrice = searchDetails.minPrice || '';
  const maxPrice = searchDetails.maxPrice || '';
  const bedRooms = searchDetails.bedRooms || '';
  const bathRooms = searchDetails.bathRooms || '';
  const homeType = searchDetails.homeType ? searchDetails.homeType.join(',') : '';

  navigate(`/search?address=${address}&minPrice=${minPrice}&maxPrice=${maxPrice}&bedRooms=${bedRooms}&bathRooms=${bathRooms}&homeType=${homeType}`);
};


  const handleDelete = async () => {
    Axios.delete(`${API}/api/v1/property/delete/${searchDetails._id}`, { data: { uId } })
      .then((response) => {
        // console.log("Response from backend:", response.data);
        if (response.data.success) {
          setOpenAlert(true);
          refreshPage();
        } else {
          console.error("Deletion was not successful");
          setOpenAlert(false);
        }
      })
      .catch((error) => {
        console.error("Delete error:", error.response.data);
        setOpenAlert(false);
      });

    setIsDialogOpen(false);
  };

  return (
    <>
    <Card className="custom-card" sx={cardStyle}>
      <CardContent onClick={handleNavigateToBuy}>
        <Typography gutterBottom variant="body1" component="div">
          Address: {searchDetails.address}
        </Typography>
        <Typography gutterBottom variant="body2" component="div">
        Price Range: {(() => {
          const minPrice = searchDetails.minPrice;
          const maxPrice = searchDetails.maxPrice;

          if (minPrice === null && maxPrice === null) {
            return 'Any Price Range';
          } else if (minPrice !== null && maxPrice === null) {
            return `Min Rs.${minPrice} - Max Any`;
          } else if (minPrice === null && maxPrice !== null) {
            return `Min Any - Max Rs.${maxPrice}`;
          } else {
            return `Min Rs.${minPrice} - Max Rs.${maxPrice}`;
          }
        })()}

      </Typography>
      </CardContent>
      <CardActions>
        <IconButton style={deleteIconStyle} onClick={() => setIsDialogOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </CardActions>

      <DeleteProperty
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleDelete}
      />
      </Card>
          <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleAlertClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Successfully Deleted!
          </Alert>
        </Snackbar>
    </>
  );
}
