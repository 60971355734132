// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* VerificationPage.css */

.verification__page {
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 45vh;
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .email__address {
    font-size: 16px;
  }
  
  .email {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .instruction {
    font-size: 16px;
    margin-top: 20px;
  }
  
  .resend__button {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .resend__button:hover {
    background-color: #0056b3;
  }`, "",{"version":3,"sources":["webpack://./src/components/user/page/VerificationPage.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* VerificationPage.css */\n\n.verification__page {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 40px;\n    text-align: center;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    height: 45vh;\n    background-color: #f9f9f9;\n  }\n  \n  h1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .email__address {\n    font-size: 16px;\n  }\n  \n  .email {\n    font-size: 20px;\n    font-weight: bold;\n    margin-top: 5px;\n  }\n  \n  .instruction {\n    font-size: 16px;\n    margin-top: 20px;\n  }\n  \n  .resend__button {\n    margin-top: 30px;\n    padding: 10px 20px;\n    font-size: 16px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .resend__button:hover {\n    background-color: #0056b3;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
